import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';
declare var _iframeUrl: any;

@Component({
  selector: 'app-mishpacha-title',
  templateUrl: './mishpacha-title.component.html',
  styleUrls: ['./mishpacha-title.component.styl']
})
export class MishpachaTitleComponent implements OnInit {
  public mediot: Observable<any>;
  public length_:number;
  public urlIframe: string;
  isLoading = true;


  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.urlIframe = _iframeUrl;
    this.mediaService.dataMedia
      .pipe(map(items => items.filter(item => item.sectionId == 5)))
      .subscribe(d => {
        this.mediot = d;
        this.isLoading=false;
        this.length_ = d.length;
        
      
      });

  }

}
