<nav class="navbar navbar-expand-sm bg-light  fixed-top">
    <div class="container">
     <button class="navbar-toggler fa fa-bars " type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
     </button>
     <a href="#" [routerLink]="['/']" class="navbar-brand logo-right" (click) ="scroll0()" >
      <span>
          <img src="assets\img\giyusLogo.SVG" class="logo-img" alt="לזכרם - משטרת ישראל" title="לזכרם - משטרת ישראל">
       </span></a>

     <div class="collapse navbar-collapse justify-content-md-center" id="navbarCollapse">
       <ul class="navbar-nav ">
           <li *ngFor="let item of menu" [ngClass]="GetLiClass(item)" >
            <a *ngIf="item.items.length == 0" href= {{item.link}} class="nav-link  text-muted">{{item.name}}</a>
          <div *ngIf="item.items.length >0">
            <a class="nav-link dropdown-toggle  text-muted " href="#" data-toggle="dropdown"> {{item.name}}</a>
            <div class="dropdown-menu text-center">
              <a  *ngFor="let child of item.items" href={{child.link}} class="dropdown-item ">{{child.name}}</a>
             </div>  
            </div>
        </li>
           </ul>
       </div>
       <div class="live-btn"  *ngIf="mediot">
        <a href="{{mediot[0].link}}" >
        <span  [innerHTML]="mediot[0]?.text"></span>
      </a>
       </div>
       <a href="#" [routerLink]="['/']" class="navbar-brand logo-left" (click) ="scroll0()" >
        <span>
            <img src="assets\img\logoLeft.png" class="logo-img" alt="לזכרם - משטרת ישראל" title="לזכרם - משטרת ישראל">
         </span></a>
    </div>
    </nav>
