import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ComponentRef, DebugElement, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { EventSignOn } from '../entities/EventSignOn';
import { InputsValidators } from '../entities/Validators';
import { EventMishtatefComponent } from '../event-mishtatef/event-mishtatef.component';
import { Event_ } from '../entities/Event';


declare var _apiUrl: any;
declare var _CaptchaKey: any;

@Component({
  selector: 'app-event-sign-on',
  templateUrl: './event-sign-on.component.html',
  styleUrls: ['./event-sign-on.component.styl']
})

export class EventSignOnComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient, private resolver: ComponentFactoryResolver, private activeRoute: ActivatedRoute, private validators: InputsValidators) { }

  public kirva;
  public noflim;
  public arim;
  public events;
  isSubmit = false;
  eventSignOn: EventSignOn;
  signOnErr = false;
  signOnSucsses = false;
  isLoading = false;
  generalData: any;
  nofesh = false;
  hasaa: false;
  nohasaa = true;
  public secret: string = _CaptchaKey;
  idNirsham: number;
  mishtatefInEdit = false;
  currentEvent = new Event_;
  addMishtatef_: boolean = true;
  textAttention: string;
  sizes;
  c_ref_inEdit;



  @ViewChild("containMishtatfim", { read: ViewContainerRef } as any) containMishtatfim;
  @ViewChild(RecaptchaComponent) recaptcha1: RecaptchaComponent;





  // mishtatfim: MishtatefEvent[] = [];
  public mishtatfim: any[] = [];
  fgInputs = new FormGroup({

    LastName: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    FirstName: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    TZ: new FormControl('', [Validators.maxLength(9), this.validators.validateTz]),//(?i)^(?=.*[a-z])[a-z0-9]{8,20}$
    Age: new FormControl(null, [Validators.min(0), Validators.max(120)]),
    Address: new FormControl('', null),
    Email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern('\\w+([-+.\']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*')]),
    SizeOfShirt: new FormControl(null, null),
    Phone: new FormControl('', [Validators.maxLength(10), Validators.required, Validators.pattern('^\\+?(972|0)(\\-)?0?(([23489]{1}\\d{7})|[5]{1}\\d{8}|[7]{1}\\d{8})$')]),
    Phone2: new FormControl('', [Validators.maxLength(10), Validators.pattern('^\\+?(972|0)(\\-)?0?(([23489]{1}\\d{7})|[5]{1}\\d{8}|[7]{1}\\d{8})$')]),
    Attendant: new FormControl('', null),
    Nofel: new FormControl(null, []),
    Kirva: new FormControl(null, []),
    Unit: new FormControl('', [Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Erua: new FormControl(null, [Validators.required]),
    Eshun: new FormControl(false, null),
    SugHagaa: new FormControl(false, Validators.required),
    // Area:new FormControl(null,null),
    Eir: new FormControl(null, []),//([Validators.required, Validators.pattern("this.hasaa==true")])),
    Comments: new FormControl('', null),
  });



  ngOnInit(): void {

    this.fgInputs.get('SugHagaa').setValue(null);
    this.fgInputs.get('SugHagaa').valueChanges.subscribe(val => {
      if (this.hasaa) {
        this.fgInputs.controls['Eir'].setValidators([Validators.required]);
      } else {
        this.fgInputs.controls['Eir'].clearValidators();
      }
      this.fgInputs.controls['Eir'].updateValueAndValidity();
    });

    this.hasaa = false;

    this.http.get(_apiUrl + 'EventSignOnData')
      .subscribe(n => {
        this.generalData = n;
        this.kirva = this.generalData.kirva;
        this.noflim = this.generalData.noflim;
        this.kirva[0].test = 1;

      });
    this.http.get(_apiUrl + 'Event')
      .subscribe(n => {
        this.events = n;

        if (this.activeRoute.snapshot.paramMap.get('eventid')) {
          this.currentEvent = this.events.find((e) => e.id == this.activeRoute.snapshot.paramMap.get('eventid'));
          if (this.currentEvent.event_name.includes("מפגש אלמנים")) {
            debugger;
            this.addMishtatef_ = false;
            this.mishtatefInEdit = false;
          }
          else
            this.addMishtatef_ = true;

          (this.currentEvent.event_name.includes("נופש")) ? this.nofesh = true : this.nofesh = false;
          this.fgInputs.get("Erua").setValue(this.currentEvent['id']);
          this.fgInputs.get('Erua').disable()
        }
      });

    this.http.get(_apiUrl + 'EventSignOnArim')
      .subscribe(n => {
        this.arim = n;//[{key:'2',val:'5'},{key:'3',val:'5'}]
      })

    this.http.get(_apiUrl + 'Sizes')
      .subscribe(n => {
        this.sizes = n;//[{key:'2',val:'5'},{key:'3',val:'5'}]
      })
  }
  onChangeErua(event) {
    this.currentEvent = event;
    (event.event_name.includes("נופש")) ? this.nofesh = true : this.nofesh = false;
    if (event.event_name.includes("מפגש אלמנים")) {
      this.addMishtatef_ = false
      this.mishtatefInEdit = false;
    } else this.addMishtatef_ = true;

    if (this.c_ref_inEdit)
      this.c_ref_inEdit.instance.nofesh = this.nofesh;
    if (this.c_ref_inEdit)
      this.c_ref_inEdit.instance.nofesh = this.nofesh;


  }


  onChangeArea(event) {
    this.http.get(_apiUrl + 'EventSignOnArim/' + event.key)
      .subscribe(n => {

        this.arim = n['arim'];

      });
  }
  send() {
    (this.mishtatefInEdit) ? this.textAttention = "שים לב! יש לשמור את פרטי המשתתף הנוסף." : "";
    if (this.hasaa)
      this.fgInputs.controls['Eir'].setValidators([Validators.required]);
    console.log('send');
    this.isSubmit = true;
    this.signOnErr = false;
    this.signOnSucsses = false;

    if (this.fgInputs.valid && !this.mishtatefInEdit) {
      this.isLoading = true;
      this.recaptcha1.reset();
      this.recaptcha1.execute();
    }
  }

  public submitWithCaptcha(captchaResponse: string) {
    this.eventSignOn = new EventSignOn();
    this.eventSignOn.GoogleResponseToken = captchaResponse;
    this.eventSignOn.FirstName = this.fgInputs.get('FirstName').value;
    this.eventSignOn.LastName = this.fgInputs.get('LastName').value;
    this.eventSignOn.Tz = this.fgInputs.get('TZ').value;
    this.eventSignOn.Age = this.fgInputs.get('Age').value ?? 0;
    this.eventSignOn.Address = this.fgInputs.get('Address').value;
    this.eventSignOn.Email = this.fgInputs.get('Email').value;
    this.eventSignOn.SizeOfShirt = this.fgInputs.get('SizeOfShirt').value ?? 0;
    this.eventSignOn.Phone = this.fgInputs.get('Phone').value;
    this.eventSignOn.Phone2 = this.fgInputs.get('Phone2').value;
    this.eventSignOn.Eshun = this.fgInputs.get('Eshun').value;
    this.eventSignOn.NofelId = this.fgInputs.get('Nofel').value ?? 0;
    this.eventSignOn.KirvaId = this.fgInputs.get('Kirva').value ?? 0;
    this.eventSignOn.Attendant = this.fgInputs.get('Attendant').value;
    this.eventSignOn.Yechida = this.fgInputs.get('Unit').value;
    this.eventSignOn.Hasaa = this.fgInputs.get('SugHagaa').value;
    this.eventSignOn.EirId = this.fgInputs.get('Eir').value ? this.fgInputs.get('Eir').value : 0;
    this.eventSignOn.EventId = this.fgInputs.get('Erua').value;
    this.eventSignOn.Comments = this.fgInputs.get('Comments').value;


    if (this.eventSignOn.EventId > 0 && captchaResponse) {

      this.http.post(_apiUrl + 'EventSignOn', this.eventSignOn)
        .subscribe(id => {
          this.signOnSucsses = true;
          this.signOnErr = false;
          this.isLoading = false;
          this.fgInputs.reset();
          this.isSubmit = false;
          if (this.mishtatfim.length == 0)
            this.router.navigate(['eventsignonfeedback/1']);


          this.mishtatfim.forEach(mishtatefComponentRef => {
            mishtatefComponentRef.instance.send(id);
          });
        },
          err => {
            if (this.mishtatfim.length == 0)
              this.router.navigate(['eventsignonfeedback/0']);
            console.log(err); this.signOnErr = true; this.signOnSucsses = false; this.isLoading = false; this.fgInputs.reset(); this.isSubmit = false;
          })
    }
  }

  public addMishtatef() {

    this.mishtatefInEdit = true;
    const factory = this.resolver.resolveComponentFactory(EventMishtatefComponent);
    this.c_ref_inEdit = this.containMishtatfim.createComponent(factory);

    this.c_ref_inEdit.instance.kirvaList = this.kirva;
    this.c_ref_inEdit.instance.nofesh = this.nofesh;
    this.c_ref_inEdit.instance.destroy_.subscribe(function () {
      this.mishtatefInEdit = false;
      this.c_ref_inEdit.instance.showHide = false;
      this.c_ref_inEdit.destroy();
      this.mishtatfim.forEach((element, index) => {
        if (element == this.c_ref_inEdit) {
          this.mishtatfim.splice(index, 1);
        }
      });

    }.bind(this))
    this.c_ref_inEdit.instance.check_in.subscribe(function () {
      this.mishtatefInEdit = false;
      if (!(this.mishtatfim.indexOf(this.c_ref_inEdit) !== -1)) {

        this.mishtatfim.push(this.c_ref_inEdit);
        this.c_ref_inEdit.instance.showHide = false;
      }
      else
        this.c_ref_inEdit.instance.showHide = false;

      debugger;

    }.bind(this))

  }

  doOnSelect(c_ref) {
    this.mishtatefInEdit = true;
    c_ref.instance.showHide = true;
    this.c_ref_inEdit = c_ref;

  }
  delete(c_ref) {
    debugger;
    this.mishtatefInEdit = false;
    c_ref.destroy();
    this.mishtatfim.forEach((element, index) => {
      if (element == c_ref) this.mishtatfim.splice(index, 1);
    });
  }
  check(val) {
    this.hasaa = val;
    //this.fgInputs.get("SugHagaa").setValue(val);
  }

}
