<section id="hartzaot-mishpacha" id="carousel">
    <div class="container text-center my-3 bg-white">
        <h2 *ngIf="mediot" class="text-center text-dark">{{mediot[0].sectionName}}</h2>
        <div *ngIf="mediot" id="multi-item-example" class=" carousel slide carousel-multi-item" data-ride="carousel"
            data-interval="false">
                <a *ngIf="mediot.length > 3" class="btn-floating" href="#multi-item-example" data-slide="prev"><i
                        class="fa fa-chevron-left"></i></a>
                <a *ngIf="mediot.length > 3" class="btn-floating" href="#multi-item-example" data-slide="next"><i
                        class="fa fa-chevron-right"></i></a>            
            <div *ngIf="rows" class="carousel-inner " role="listbox">
                <div *ngFor="let row of rows; let first = first;" [class.active]="first" class="carousel-item ">
                    <div class="row col-12">
                        <div *ngFor="let media of row ; let i = index" class="col-md-4">
                            <div class="card mb-2">
                                <a href="{{media.link}}" > 
                                <img class="card-img-top" src={{mediaService.mediaPath+media.url}} alt="{{media.title}}"
                                    title={{media.title}}>
                                <div class="card-body">
                                    <h4 class="card-title">{{media.title}}</h4>
                                    <p [innerHTML]="media.text" class="card-text"></p>                                 
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>