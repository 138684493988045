<section class="py-2" id="family-events">
    <h2 class="text-center mb-4">אירועים</h2>
    <div class="container ">
        <div class="row justify-content-center" id="ads">
            <div *ngFor="let event of events | slice:0:countOfShow" class=" mt-3 col-6 col-md-2 text-center">
                <div class="card rounded">
                    <span class="event-type-name">{{event.type_name}}</span>
                    <div class="text-center">
                        <a class="jjj" [ngClass]="{'myhover' : !event.page_id}" [href]="event.page_id ?  eventPath + event.page_id : 'javascript:void(0)' | safe">
                            <span class="card-notify-badge"><img
                                    src={{imgUrlPath+event.type_image}}
                                    alt="{{event.type_name}}" title="{{event.type_name}}" /></span>                                   
                            <span class="mainSpan">
                                <span class="date">{{event.date | MyFormatDatePipe}}</span>
                                <span class="hour">{{event.date | MyFormatTimePipe}}</span>
                            </span></a>
                    </div>
                </div>
                <h5 title="{{event.event_name}}"><a [href]="event.page_id ?  eventPath + event.page_id : 'javascript:void(0)' | safe">{{event.event_name}}</a></h5>
                <h6 title="{{event.location}}">{{event.location}}</h6>
                <div [innerHTML]="event.content">
                </div>
                <small title="{{event.location}}"></small><a href="#contactUs">לרישום</a>
            </div>
          
            
        </div>
        <div *ngIf="lengthEvents > 6" class="mb-3 font-weight-bold">
            <a class="text-left" (click)="showOrHidden()" *ngIf="!showAll  ">הצג הכל</a>
            <a (click)="showOrHidden()" *ngIf="showAll">הסתר</a>
        </div>
    </div>
    


</section>