<section id="yizkor-title" class="yizkor-title" [class]=" isLoading ? 'isLoading' : '' ">
  <header>
    <div>
      <ng-container *ngFor="let mediavieo of mediot | filterMediaPipe:2; let i = index ">

        <video class="d-block w-100 " class="{{'vid-display-' + i}}" playsinline="playsinline" autoplay="autoplay" [muted]="false" loop="loop" controls="controls">
          <source src={{mediaService.mediaPath+mediavieo.url}} type="video/mp4">
        </video>
        <div *ngIf="mediavieo.text" class="carousel-caption d-none d-md-block container" [innerHTML]="mediavieo.text"></div>
      </ng-container>
      <ng-container *ngFor="let mediaYout of mediot | filterMediaPipe:3; let i = index ">

        <iframe class="h-700 bg-dark text-center w-100 vh-100 d-block" [src]="urlIframe + mediaYout.url | safe"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <div *ngIf="mediaYout.text" class="carousel-caption d-none d-md-block container" [innerHTML]="mediaYout.text"></div>

      </ng-container>
    </div>
    <div *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 0" id="carouselExampleControls" class="carousel slide position-relative " data-ride="carousel">
      <div class="carousel-inner">
        <diV *ngFor="let mediaimg of mediot | filterMediaPipe:1; let i = index "
          [ngClass]="{'active': i == 0, 'carousel-item': mediaimg.mediaType!=4, 'vh-100': mediaimg.mediaType==2}"
          class=" ">
          <img src={{mediaService.mediaPath+mediaimg.url}} class="d-block w-100" alt="{{mediaimg.title}}"
            title="{{mediaimg.title}}">
          <div class="carousel-caption d-none d-md-block container" [innerHTML]="mediaimg.text" *ngIf="mediaimg.text" style="bottom: 100px;"></div>
          <div class="carousel-caption d-none d-md-block container">
            <button (click)="search()" type="button" class="btn" style="width: 340px;
            height: 49px;
            flex-shrink: 0;
            border-radius: 14px;
            border: 1px solid #D2AD55;
            background: #F2F2F2;
            box-shadow: 3px 4px 20px 5px #D7A632;
            color: #323232;
            text-align: right;
            font-family: Rubik;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            justify-content: space-evenly;
            display: flex;
            align-items: center;"><div>חללי</div><div>״חרבות ברזל״</div> 
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="26" viewBox="0 0 14 26" fill="none">
              <path d="M0.657894 12.6354C0.557151 12.7299 0.499999 12.8619 0.499999 13C0.499999 13.1381 0.557151 13.2701 0.657894 13.3646L12.6579 24.623C12.8033 24.7594 13.0158 24.7964 13.1987 24.7172C13.3816 24.638 13.5 24.4577 13.5 24.2583L13.5 1.74167C13.5 1.54233 13.3816 1.36204 13.1987 1.28284C13.0158 1.20363 12.8033 1.24064 12.6579 1.37703L0.657894 12.6354Z" fill="#C09734" stroke="#C09734" stroke-linejoin="round"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="26" viewBox="0 0 14 26" fill="none">
              <path d="M0.657894 12.6354C0.557151 12.7299 0.499999 12.8619 0.499999 13C0.499999 13.1381 0.557151 13.2701 0.657894 13.3646L12.6579 24.623C12.8033 24.7594 13.0158 24.7964 13.1987 24.7172C13.3816 24.638 13.5 24.4577 13.5 24.2583L13.5 1.74167C13.5 1.54233 13.3816 1.36204 13.1987 1.28284C13.0158 1.20363 12.8033 1.24064 12.6579 1.37703L0.657894 12.6354Z" fill="#C09734" stroke="#C09734" stroke-linejoin="round"/>
            </svg>
            </button>
          </div>
        </diV>
      </div>
      <a *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 1" class="carousel-control-prev mt-5" href="#carouselExampleControls" role="button"
        data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 1" class="carousel-control-next position-absolute float-left mt-70"
        href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div> 

  </header>
</section>
<div class="loadingBgDiv"  *ngIf="isLoading">
  <img src="assets\img\laoding.gif">
</div>
