import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';


declare var _apiUrl: any;
declare var _mediaPath: any;
declare var _pageUrl:any;

@Component({
  selector: 'app-mishpacha-events',
  templateUrl: './mishpacha-events.component.html',
  styleUrls: ['./mishpacha-events.component.styl']
})


export class MishpachaEventsComponent implements OnInit {

  public events: any;
  public imgUrlPath: string;
  public countOfShow: number;
  public showAll: boolean;
  public lengthEvents: number;
  public eventPath:string;


  constructor(private http: HttpClient) {
    this.imgUrlPath = _mediaPath;
    this.eventPath=_pageUrl;

  }



  ngOnInit(): void {
    this.countOfShow = 6;

    this.http.get(_apiUrl + 'Event')
      .subscribe(e => {
        this.events = e;
        this.lengthEvents = this.events.length;
      });
  }
  public showOrHidden() {
    if (this.showAll)
      this.countOfShow = 6;
    else
      this.countOfShow = this.lengthEvents;
    this.showAll = !this.showAll;
  }


}
