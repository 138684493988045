import { NumberValueAccessor } from "@angular/forms";

export class Event_ {
    id:number;
    event_type_id:number;
    page_id:NumberValueAccessor;
    event_name:string;
    location:string;
    date:Date;
    image:string;
    content:string;
    type_name:string;
    type_image:string;

   
}
