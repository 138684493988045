export class Contact{
  GoogleResponseToken: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  Subject: string;
  Unit: string;
  CategoryID: number;
  KirvaId: number;
  NofelId: number;

}