<form [formGroup] = "fgInputs">  
<div class="row addCandle" [class]=" isLoading ? 'isLoading' : '' ">
  
      <div class="col-md-6">
        <div class="form-group">
          <label for="firstName" class="float-right">שמך הפרטי</label>
                  <input type="text" formControlName="FirstName"  maxlength="20" >
                  <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא תקין </span>
                  <span class="errValid" *ngIf="isSubmit &&  fgInputs.get('FirstName').hasError('required')">שדה חובה </span>
             </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="firstName" class="float-right">שם משפחתך</label>
                  <input type="text" formControlName="LastName"  maxlength="20" >
                  <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא תקין </span>
                  <span class="errValid" *ngIf="isSubmit &&  fgInputs.get('LastName').hasError('required')">שדה חובה </span>
             </div>
      </div>
      <div class="col-md-12">
        <div class="col-md-12" >                              
                <div class="form-group">
                 <div><label for="firstName">תוכן</label></div> 
                   <div> <textarea  class="form-comtrol" rows="3" cols="50" maxlength="50" formControlName="Text" ></textarea></div>
                   <span class="errValid" *ngIf="fgInputs.get('Text').hasError('pattern')"> לא תקין </span>
                   <span class="errValid" *ngIf="isSubmit &&  fgInputs.get('Text').hasError('required')">שדה חובה </span>
       
                </div>       
        </div>
        
        <div class="col-md-12 ">                            
            <div class="form-group center">
              <button type="button" class="btn btn-outline-warning float-left" (click)="addCandle()">שלח/י</button>
            </div>   
            <div class="alert alert-success" *ngIf="addCandleSucsses">
              <p>פנייתך התקבלה בהצלחה</p>
            </div>
 
            <div class="alert alert-danger" *ngIf="addCandleErr">
              <p>אירעה שגיאה בלתי צפויה</p>
            </div>
         
       </div>
           
       </div>   


  <re-captcha #captchaRef="reCaptcha"  size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}"></re-captcha>
</div>
</form>

<div class="loadingDiv"  *ngIf="isLoading">
  <img src="assets\img\laoding.gif">
</div>