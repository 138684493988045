export class SearchParams{
    public  GoogleResponseToken :string;
    public  FreeText: string;
    public  FirstName :string;
    public  LastName: string;
    public  MachozId: number;
    public  UnitId: number;
    public DateNefila: Date;
    public  DateNefilaFrom: Date;
    public  DateNefilaTill: Date;
    public  CemeteryId: number
    public  Ishi : string;
    public  SibatMavetID: number;

}