<div id="search" class="text-center p-5 ">
      <div class="row">
          <div class="col">
              <h3 *ngIf="mediot && isIron == 'false'" title="{{mediot[0]?.title}}">{{mediot[0]?.title}}</h3>
              <form action="" [formGroup]="fgInputs" class="justify-content-center">
                <div>
                <ng-select class="lg-mb-20 xs-mb-15" [items]="noflim" bindLabel="val" bindValue="key"
                  formControlName="Nofel" title="בחר חלל " notFoundText="לא נמצאו תוצאות" [clearable]="true"
                  placeholder="בחר חלל">
                </ng-select> 
                      <button (click)="searchNofel()" class="btn btn-warning mb-2 ">חפש</button>
               <div *ngIf="isNoSelect" class="alert alert-danger">
                 <p>חובה לבחור חלל</p>
               </div>
                    </div>
                      <div class="align-items-center">
                        <a class="btn btn-link text-dark text-white "  data-toggle="collapse" data-target="#collapse-btn">חיפוש מתקדם</a>
                        </div>
                        <div class="collapse" id="collapse-btn">
                          <hr>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for="firstName">שם פרטי</label>
                              <input type="text" class="form-control" formControlName="FirstName" maxlength="20">
                              <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא תקין </span>
                           
                            </div>
                            <div class="form-group col-md-6">
                              <label for="firstName">שם משפחה</label>
                              <input type="text" class="form-control" formControlName="LastName" maxlength="20">
                              <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא תקין </span>
                           
                            </div>
                            <div class="form-group col-md-6">
                              <label for="machoz">מחוז/אגף</label>
                              <ng-select class="lg-mb-20 xs-mb-15" [items]="mechozot" bindLabel="val" bindValue="key"
                              formControlName="selectMachoz" title="בחר מחוז" notFoundText="לא נמצאו תוצאות" [clearable]="true"
                              placeholder="בחר מחוז">
                            </ng-select>
                             </div>
                            <div class="form-group col-md-6">
                              <label for="unit">יחידה</label>
                              <ng-select class="lg-mb-20 xs-mb-15" [items]="units" bindLabel="val" bindValue="key"
                              formControlName="selectUnit" title="בחר יחידה" notFoundText="לא נמצאו תוצאות" [clearable]="true"
                              placeholder="בחר יחידה">
                            </ng-select></div>
                            <div class="form-group col-md-6">
                              <label for="">תאריך נפילה מ:</label>
                              <mat-form-field >
                               
                                <input  [min]="minDate" [max]="maxDate" matInput [matDatepicker]="fromPicker" formControlName="FromDate">
                                <mat-datepicker-toggle maxSuffix [for]="fromPicker"></mat-datepicker-toggle>
                                <mat-datepicker #fromPicker></mat-datepicker>
                              </mat-form-field> 
                            </div>
                            <div class="form-group col-md-6">
                              <label for="unit">תאריך נפילה עד:</label>
                              <mat-form-field >
                                <input  [min]="minDate" [max]="maxDate"  matInput [matDatepicker]="toPicker" formControlName="ToDate">
                                <mat-datepicker-toggle maxSuffix [for]="toPicker"></mat-datepicker-toggle>
                                <mat-datepicker #toPicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                        
                            <div class="form-group col-md-6">
                              <label for="">בית עלמין</label>
                              <ng-select class="lg-mb-20 xs-mb-15" [items]="cemeteries" bindLabel="val" bindValue="key"
                              formControlName="selectCemetery" title="בחר מקום קבורה" notFoundText="לא נמצאו תוצאות"
                              [clearable]="true" placeholder="בחר מקום קבורה">
                            </ng-select>  </div>
                            <div class="form-group col-md-6">
                              <label for="">מספר אישי</label>
                              <input type="text" class="form-control" formControlName="Ishi" maxlength="20">
                              <span class="errValid" *ngIf="fgInputs.get('Ishi').hasError('pattern')"> לא תקין </span>
                           
                            </div>
                            <div *ngIf="isNoOne" class="alert alert-danger">
                              <p>חובה לבחור לפחות שדה אחד</p>
                            </div>
                            <div *ngIf ="isNoSearchResult">
                              <span  class="isNoSearchResult">לא נמצאו תוצאות לחיפוש המבוקש</span>                    
                      
                            </div>
                           <div   *ngIf="isSearchResult"  data-toggle="collapse" data-target="#collapse-btn"  class="text-center w-100">  <button (click)="search()" type="button" class="btn btn-dark">חפש</button></div>
                           <div  *ngIf="!isSearchResult"  class="text-center w-100">  <button (click)="search()" type="button" class="btn btn-dark">חפש</button></div>
                           
                          </div>
                        </div>
              </form>
          </div>
      </div>
  <div class="loadingDiv"  *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
</div>

</div>
            <!--<div class="form-inline  justify-content-center  ">
              <label for="firstName" class="mr-sm-2">שם החלל</label>
              <input type="text" formControlName="FreeText" id="firstName" class="form-control mr-2"
                placeholder="שם החלל">
              <button (click)="search()" class="btn btn-dark mr-1 mt-1" type="button">חפש</button>

              <a (click)="isSpecificSearch = !isSpecificSearch" class="text-color-black link-black mr-3 mt-2"
                data-target="">חיפוש מתקדם</a>
            </div>
            <div *ngIf="isSpecificSearch" class="m-top-40">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <span class="upperText">שם פרטי</span>
                  <input type="text" formControlName="FirstName" maxlength="20">
                  <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא תקין </span>
                </div>
                <div class="form-group col-md-4">
                  <span class="upperText">שם משפחה</span>
                  <input type="text" formControlName="LastName" maxlength="20">
                  <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא תקין </span>
                </div>
                <div class="form-group col-md-4">
                  <span class="upperText">מספר אישי </span>
                  <input type="text" formControlName="Ishi" maxlength="20">
                  <span class="errValid" *ngIf="fgInputs.get('Ishi').hasError('pattern')"> לא תקין </span>
                </div>
                <div class="form-group col-md-4">
                  <!-- <span class="upperText" >מתאריך</span> -->
   <!--               <mat-form-field >
                    <mat-label>מתאריך</mat-label>
                    <input matInput [matDatepicker]="fromPicker" formControlName="FromDate">
                    <mat-datepicker-toggle maxSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                  <!-- <span class="errValid" *ngIf="fgInputs.get('FromDate').hasError('pattern')"> לא תקין  </span> -->
    <!--            </div>
                <div class="form-group col-md-4">
                  <!-- <span class="upperText" >מתאריך</span> -->
    <!--              <mat-form-field >
                    <mat-label>עד תאריך</mat-label>
                    <input matInput [matDatepicker]="toPicker" formControlName="ToDate">
                    <mat-datepicker-toggle maxSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                  <!-- <span class="errValid" *ngIf="fgInputs.get('FromDate').hasError('pattern')"> לא תקין  </span> -->
 <!--               </div>
                <div class="form-group col-md-4">
                  <!-- <span class="upperText" >מתאריך</span> -->
    <!--             <mat-form-field >
                    <mat-label> תאריך</mat-label>
                    <input matInput [matDatepicker]="exactPicker" formControlName="ExactDate">
                    <mat-datepicker-toggle maxSuffix [for]="exactPicker"></mat-datepicker-toggle>
                    <mat-datepicker #exactPicker></mat-datepicker>
                  </mat-form-field>
                  <!-- <span class="errValid" *ngIf="fgInputs.get('FromDate').hasError('pattern')"> לא תקין  </span> -->
 <!--               </div>
                <div>
                  <ng-select class="lg-mb-20 xs-mb-15" [items]="mechozot" bindLabel="val" bindValue="key"
                    formControlName="selectMachoz" title="בחר מחוז" notFoundText="לא נמצאו תוצאות" [clearable]="true"
                    placeholder="בחר מחוז">
                  </ng-select>
                </div>
                <div class="form-group col-md-4">
                  <ng-select class="lg-mb-20 xs-mb-15" [items]="units" bindLabel="val" bindValue="key"
                    formControlName="selectUnit" title="בחר יחידה" notFoundText="לא נמצאו תוצאות" [clearable]="true"
                    placeholder="בחר יחידה">
                  </ng-select>
                </div>
                <div class="form-group col-md-4">
                  <ng-select class="lg-mb-20 xs-mb-15" [items]="cemeteries" bindLabel="val" bindValue="key"
                    formControlName="selectCemetery" title="בחר מקום קבורה" notFoundText="לא נמצאו תוצאות"
                    [clearable]="true" placeholder="בחר מקום קבורה">
                  </ng-select>
                </div>
                <button (click)="search()" type="button" class="btn btn-dark btn-block">חפש</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
-->
<re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}">
</re-captcha>