<section id="lz" class="py-5 text-center ">
    <div class="container">
        <div class="row justify-content-center align-self-center">
            <div class="col">
                <div class="info-header mb-5">
                    <div [innerHTML]="media.text" *ngFor="let media of mediot | filterMediaPipe:4; ">

                    </div>

                    <div id="carouselExampleSlidesOnly" class="carousel slide row mb-4" data-ride="carousel">
                        <div *ngIf="rows" class="carousel-inner " role="listbox">
                            <div *ngFor="let row of rows; let first = first;" [class.active]="first"
                                class="carousel-item ">
                                <div class="row col-12">
                                    <div *ngFor="let media1 of row ; let i = index "
                                        class="col-md-4 align-self-center text-center ">
                                        <a *ngIf="media1.link" href="{{media1.link}}">
                                            <img src={{mediaService.mediaPath+media1.url}} alt="{{media1.title}}"
                                                class="img-fluid w-100">
                                            <div *ngIf="media1.text" class="carousel-caption d-none d-md-block"
                                                [innerHTML]="media1.text"></div>
                                        </a>
                                        <img *ngIf="!media1.link" src={{mediaService.mediaPath+media1.url}}
                                            alt="{{media1.title}}" class="img-fluid w-100">
                                        <div *ngIf="media1.text && !media1.link"
                                            class="carousel-caption d-none d-md-block" [innerHTML]="media1.text">
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>