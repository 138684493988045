import { Component, OnInit ,Input } from '@angular/core';

declare var _imgUrlPath: any;

@Component({
  selector: 'app-nofel',
  templateUrl: './nofel.component.html',
  styleUrls: ['./nofel.component.styl']
})
export class NofelComponent implements OnInit {

  @Input() nofel: any;
  @Input() isSearchResult: any = false;
  urlPath = _imgUrlPath ;
  imgDefalt = 'nofelDefault.jpg';
  nafalTxt = "נפל";
  public id: string;

  constructor() { }

  ngOnInit(): void {
   this.id  = this.nofel.id.toString();
    this.urlPath = _imgUrlPath + this.id.padStart(6, '0') + '/' ;

    if(this.nofel.benBat == 'בת'){
      this.nafalTxt = "נפלה";
    }
  
  }

  onImgError(event){
   if(this.nofel.benBat == 'בת')
    {
      event.target.src = 'assets/img/woman.png';
    }
    else{
      event.target.src = 'assets/img/man.png';
    }

   }

}
