<section id="yachad-mishpacha" class="lz-bg-color1 sec-family">

    <div class="container">

        <div class="row">
            <ng-container *ngFor="let media1 of mediot">
                <div *ngIf="media1.mediaType==1 || media1.mediaType==3" class="col-md-4">

                    <a *ngIf="media1.mediaType==1 && media1.link" href="{{media1.link}}">
                        <!-- [href]="media1.link ? media1.link : 'javascript:void(0)' | safe" -->
                        <img class="w-100 h-100" src={{mediaService.mediaPath+media1.url}} alt="{{media1.title}}"
                            title="{{media1.title}}"></a>
                    <img *ngIf="media1.mediaType==1 && !media1.link" class="w-100 h-100" src={{mediaService.mediaPath+media1.url}} alt="{{media1.title}}"
                        title="{{media1.title}}">
                    <iframe *ngIf="media1.mediaType==3" class="mt-5 text-center w-100  d-block"
                        [src]="iframeUrl + media1.url | safe" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>

                </div>

                <div *ngIf="media1.mediaType==4" class="col-md-8 align-self-center text-center text-white  ">

                    <div [innerHTML]="media1.text">{{media1.text}}</div>


                </div>
            </ng-container>



        </div>

    </div>

</section>