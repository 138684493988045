import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

declare var _apiUrl: any ;

@Component({
  selector: 'app-with-the-families',
  templateUrl: './with-the-families.component.html',
  styleUrls: ['./with-the-families.component.styl']
})
export class WithTheFamiliesComponent implements OnInit {

  page: any;  sahreTitle: any;  shareImg: any;
  constructor(private http: HttpClient, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.http.get(_apiUrl + 'Page/-1'  )
    .subscribe(n => {this.page = n ; console.log(this.page);
      this.sahreTitle = this.page.page.title;
      this.shareImg = window.location.origin + '/src/assets/img/shareImg.jpg';
      this.titleService.setTitle(this.page.page.title);
      this.metaTagService.updateTag(
        { name: 'description', content: this.page.page.description }
      );

      this.metaTagService.addTags([
        { name: 'keywords', content: this.page.page.keyword },
        { name: 'og:title', content: this.page.page.title },
        { name: 'og:image', content: window.location.origin + '/src/assets/img/shareImg.jpg' },
        { name: 'og:description', content: this.page.page.description },
        { name: 'og:url' , content: window.location.href }
      ]);
    
    });
  
  
  }


}
