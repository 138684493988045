import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { SearchParams } from '../entities/SearchParams';
import { SearchResults } from '../entities/SearchResults';
import { MediaSectionsService } from '../services/mediaSectionsService';
import { map } from 'rxjs/operators';

declare var _apiUrl: any ;
declare var _CaptchaKey:any;

@Component({
  selector: 'app-candle-section',
  templateUrl: './candle-section.component.html',
  styleUrls: ['./candle-section.component.styl']
})
export class CandleSectionComponent implements OnInit {

  //@ViewChild(RecaptchaComponent) recaptcha1: RecaptchaComponent;
  public searchResult$: Observable<any>;
  searchParams: SearchParams;
  public secret: string =_CaptchaKey;
 public  countGeneralCandles :any;
 public isLoading = false;
 public mediot: Observable<any>;
 isSubmit =false;
 public noflim; 
  generalData:any;
  isSucsses = false;
  isSubmitSucsses = false;
  isSubmitErr = false;

 fgInputs = new FormGroup({
  Nofel: new FormControl(null,[Validators.required]),
  isSubmit: new FormControl('0'),
  reset: new FormControl('1')
}
);
  constructor(private http: HttpClient ,private router: Router , private searchReasult: SearchResults , public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.http.get(_apiUrl + 'AddGeneralCandle/' +false )
    .subscribe(n => {this.countGeneralCandles = n ; });
    
    this.mediaService.dataMedia
    .pipe(map(items => items.filter(item => item.sectionId == 10)))
    .subscribe(d => {
      this.mediot = d;    
    });

    this.http.get(_apiUrl + 'ContactGeneralData')
    .subscribe(n => {this.generalData = n ;  
      this.noflim = this.generalData.noflim;});
  }

  addGeneralCandle(){
    this.isLoading=true;
    this.http.get(_apiUrl + 'AddGeneralCandle/' +true )
    .subscribe(n => {this.countGeneralCandles = n ; this.isLoading=false; this.isSubmitSucsses=true; this.isSucsses=true;
      setTimeout(()=>{
        this.isSucsses=false;
      },5000);}),
      err => {this.isSubmitErr = true; this.isLoading=false;};
    
  }

  search() {
    console.log('send');
       this.isSubmit = true;
 
           if (this.fgInputs.valid) {
 
            var nav = '/nofel/' +this.fgInputs.get('Nofel').value;
            this.router.navigate([nav]);
           // this.router.navigate([nav]);
          // this.recaptcha1.reset();
          // this.isLoading = true;
          // this.recaptcha1.execute();
        
       }
      } 
      
      public submitWithCaptcha(captchaResponse: string) {
         this.searchParams = new SearchParams();
         this.searchParams.GoogleResponseToken = captchaResponse;
          this.searchParams.FreeText = this.fgInputs.get('Nofel').value;     
           this.searchResult$ =  this.http.post(_apiUrl + 'Noflim' ,this.searchParams );
           this.searchResult$.subscribe(s => { this.isLoading= false; 
           this.searchReasult.searchResult$ = this.searchResult$;
           this.router.navigate(['/searchResults'])});
       }
      }


