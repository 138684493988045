export class Candle{
  FirstName: string;
  LastName: string;
  Txt: string;
  NofelId: string;
}

export class CandleRequest{
  GoogleResponseToken: string;
Candle: Candle;
}