<section id="hodaot-mishpacha" class="bg-warning container" id="messages-mishpacha">

    <div class="container ">

        <div class="row">

            <div class="col-xl-6 mx-auto text-center">

                <div class="section-title mb-100">

                    <h2 *ngIf="mediot" title="{{mediot[0].sectionName}}">{{mediot[0].sectionName}}</h2>

                </div>

            </div>

        </div>

        <div class="row">

            <ng-container *ngFor="let media of mediot | slice:0:countOfShow">
                <div class="col-lg-4 ">

                    <div class="single-service">

                        <h4 title="{{media.title}}">{{media.title}}</h4>

                        <div *ngIf="!media.showMoreText" [innerHTML] ="Add3( media.text | slice: 0:200) " >                            
                        </div>
                        <div *ngIf="media.showMoreText" [innerHTML]="media.text">                    
                        </div>
                        <button (click) = "media.showMoreText = !media.showMoreText" *ngIf=" media.text?.length> 200" >
                            <span *ngIf="!media.showMoreText">קרא עוד</span>
                            <span *ngIf="media.showMoreText">צמצם פסקה</span>
                          </button>

                       <!-- <span [innerHTML]="media.text"></span>-->
                    </div>

                </div>
            </ng-container>

            <div *ngIf="lengthMsg > 3" class="mb-3 font-weight-bold">
                <a class="text-left" (click)="showOrHidden()" *ngIf="!showAll">הצג הכל</a>
                <a (click)="showOrHidden()" *ngIf="showAll">הסתר</a>
            </div>


        </div>

    </div>

</section>