<div id="explore-head-section">  
      <div class="row">
        <div class="col text-center py-5">
          
          <h2 *ngIf="mediot" class="display-4 text-muted mb-3" title="{{mediot[0]?.title}}">{{mediot[0]?.title}}</h2>      
        <div>
            <div>
                <button class="btnS btn btn-primary" [class]=" isFilterHe ? 'filter' : '' " (click)="onlyHebrewDay()" >תאריך עברי</button>
                <button class="btnS btn btn-primary" [class]=" isFilter ? 'filter' : '' " (click)="onlyToDay()" >תאריך לועזי</button>
              <!--  <button *ngIf="isFilter" class="btnS btn btn-primary" (click)="all()" >הצג הכל </button>    -->     
              </div>       
            
            
            <div class="container text-center my-3">
              <div id="recipeCarousel" class="carousel slide w-100" data-ride="carousel">
                  <div class="carousel-inner w-100" role="listbox">
                      <div class="carousel-item row no-gutters active"  *ngFor="let car of noflimFilterCarusleFirst">
            
                      <div class="col-4  float-left" *ngFor="let nofel of car" > 
                       
                        <app-nofel  [nofel] = nofel.nofel></app-nofel>
                    
                      </div>
              </div>
            <div class="carousel-item row no-gutters" *ngFor="let car of noflimFilterCarusle">
            
                <div class="col-4  float-left" *ngFor="let nofel of car"  > 
                  <app-nofel  [nofel] = nofel.nofel></app-nofel>
                </div>
             </div>
                 </div>
            
              <a *ngIf= "noflimFilterCarusle?.length > 0 " class="btn-floating" href="#recipeCarousel" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
              <a *ngIf= "noflimFilterCarusle?.length > 0 " class="btn-floating" href="#recipeCarousel" data-slide="next"><i class="fa fa-chevron-right"></i></a>
  
             </div>    
             </div>       
                

        </div>
        </div>





        
    </div>
  </div>
