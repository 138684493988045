import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

@Component({
  selector: 'app-mishpacha-hodaot',
  templateUrl: './mishpacha-hodaot.component.html',
  styleUrls: ['./mishpacha-hodaot.component.styl']
})
export class MishpachaHodaotComponent implements OnInit {

  public mediot: Observable<any>;
  public lengthMsg: number;
  public showAll: boolean;
  public countOfShow: number;

  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.countOfShow =3;
    this.mediaService.dataMedia
    .pipe(map(items => items.filter(item => item.sectionId == 6)))
    .subscribe(d => {
      this.mediot = d;
      this.lengthMsg = d.length;    
    });
  }

  Add3(txt) : string{
    return txt  + '...';
  }

  public showOrHidden() {
    if (this.showAll)
      this.countOfShow = 3;
    else
      this.countOfShow = this.lengthMsg;
    this.showAll = !this.showAll;
  }

}
