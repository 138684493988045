<section id="mainInfo" class="py-3 bg-light container">
    <div class="container">
        <h2 *ngIf="mediot" class="text-center text-dark">{{mediot[0].sectionName}}</h2>
        <div class="row">
            <div class="col-md-6">
                <a *ngIf="mediot && mediot[0].link" href="{{mediot[0].link}}">
                    <img src={{mediaService.mediaPath+mediot[0].url}} title="{{mediot[0].title}}"
                        alt="{{mediot[0].title}}" class="w-100"> </a>
                <img *ngIf="mediot && !mediot[0].link" src={{mediaService.mediaPath+mediot[0].url}}
                    title="{{mediot[0].title}}" alt="{{mediot[0].title}}" class="w-100">
            </div>
            <div class="col-md-6 ">
                <div class="list-group-flush">
                    <ng-container *ngFor="let media of mediot; let i = index">
                        <p *ngIf="i!=0" class="mb-0">
                            <img src={{mediaService.mediaPath+media.url}} title="{{media.title}}"
                                alt="{{media.title}}" />
                            <a href="{{media.link}}"><span [innerHTML]="media.text"></span></a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>