import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

@Component({
  selector: 'app-lezichram',
  templateUrl: './lezichram.component.html',
  styleUrls: ['./lezichram.component.styl']
})
export class LezichramComponent implements OnInit {
  
  public mediot:any;
  public rows:Array<any>;
  public numBottomGalleryInRow = 3;
  public mediotImg:any;




  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    if(window.innerWidth < 600)
    {
      this.numBottomGalleryInRow = 1;
    }

    this.mediaService.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==3)))
    .subscribe(d => {

        this.mediot= d ;
        this.mediotImg=this.mediot.filter(m=>m.mediaType==1);
       this.setRows();

    });
  }
  setRows() {
    
    var numRow= this.mediotImg.length/this.numBottomGalleryInRow;
    if(numRow % 1)
      numRow+=1;
    numRow=Math.floor(numRow/1);
    //this.rows= new Array(Math.floor(numRow/1));
    this.rows = new Array();
    for (let i = 0; i < numRow ; i ++) {
        this.rows[i] = this.mediotImg.slice(i * this.numBottomGalleryInRow,(i+1) * this.numBottomGalleryInRow);
     
    } 

  }

}
