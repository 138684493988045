import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var _apiUrl: any ;
declare var _mediaPath: any;

@Injectable({
  providedIn: 'root'
})

export class MediaSectionsService {
  public dataMedia:Observable<any>;
  public mediaPath:string;
  constructor(private http: HttpClient) {
    var isMobile = (window.innerWidth < 600);
   
    this.dataMedia = this.http.get(_apiUrl + 'MediaSections' );
    this.dataMedia = this.dataMedia.pipe(map(items => items.filter(item => (item.onlyMobile == isMobile && item.onlyDeskTop !=isMobile) || (!item.onlyMobile && ! item.onlyDeskTop) )));
    this.mediaPath= _mediaPath;
   }
  
  public getData(sectionId){
      // this.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==sectionId)))
      // .subscribe(d => {
      //   debugger;
      //   this.dataMedia = d ;
      //   return this.dataMedia;
        
      // });
     
  }
}
