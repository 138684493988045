import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

@Component({
  selector: 'app-yizcor-text',
  templateUrl: './yizcor-text.component.html',
  styleUrls: ['./yizcor-text.component.styl']
})
export class YizcorTextComponent implements OnInit {

  public mediot:any;
  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.mediaService.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==18)))
    .subscribe(d => {
        this.mediot= d ;
    });
  }

}
