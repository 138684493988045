import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

@Component({
  selector: 'app-mishpacha-meida-klali',
  templateUrl: './mishpacha-meida-klali.component.html',
  styleUrls: ['./mishpacha-meida-klali.component.styl']
})
export class MishpachaMeidaKlaliComponent implements OnInit {
  public mediot: Observable<any>;

  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.mediaService.dataMedia
      .pipe(map(items => items.filter(item => item.sectionId == 13)))
      .subscribe(d => {
        this.mediot = d;
       
      
      });
  }

}
