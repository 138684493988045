<div *ngIf="!isLoading" class="container nofelDetails" [class]=" isLoading ? 'isLoading' : '' ">
    <section id="info" class="py-3 mt-1">
        <div class="container text-right" *ngIf="nofelDetails">
            <div class="row">
                <div class="col-md-3">
                    <div *ngIf="nofelDetails">  <img  [src]="(urlPath + nofelDetails?.details.picLink)"   class="w-100"  
                        alt="{{nofelDetails?.details.lastName + ' '+ nofelDetails?.details.firstName}}"             
                        title= "{{nofelDetails?.details.lastName  + ' '+ nofelDetails?.details.firstName}}"
                        (error) ="onImgError($event)"></div>
                    <div class="mt-2">
                      <a (click)="addCandleClick()" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" class="btn btn-primary w-100 text-white" [href]="addCandleHref()">הדלקת נר</a>
 
                 </div>
                 </div>
                 <div class="col-md-9 ">
                    <h5 title="{{nofelDetails?.details.dargaName}}"> {{nofelDetails?.details.dargaName}}</h5>
                    <h3 title="{{nofelDetails?.details.firstName + ' ' +nofelDetails?.details.lastName}}">{{nofelDetails?.details.firstName + ' ' +nofelDetails?.details.lastName}}</h3>
                    <h4 title="{{nofelDetails?.details.ishi}}">מ.א {{nofelDetails?.details.ishi}}</h4>
                  <h5>{{benText}}</h5>
                  <br> 
                  <h5>
                        {{nafalTxt}} ביום  {{nofelDetails?.details.nefila_He_Day}}
                        {{nofelDetails?.details.nefila_He_Month}}
                        שנת {{nofelDetails?.details.nefila_He_Year}} ,
                         {{nofelDetails?.details.dateNefila | date: 'dd/MM/yyyy'}}</h5>
                    <h5> {{nofelDetails?.details.benBat}}
                         {{nofelDetails?.details.nefilaAge}}
                          {{nofloTxt}}</h5>
                          <br>
                    <h5 *ngIf="nofelDetails?.details.mekomKvura">מקום קבורה
                        {{nofelDetails?.details.mekomKvura}}
                    </h5>
                   
					<div class="button button-whitegold" *ngFor="let itur of nofelDetails?.iturim;" >{{itur.iturName}} </div>
				
                </div>
                <div class="col-md-12 mt-3" *ngIf="nofelDetails?.text"> 
                    <div *ngIf="!showMoreText" [innerHTML] ="Add3(nofelDetails?.text | slice: 0:500)" >                 
                    </div>
                    <div *ngIf="showMoreText" [innerHTML]="nofelDetails?.text ">                    
                    </div>
                    <button (click) = "showMoreText = !showMoreText" *ngIf=" nofelDetails?.text?.length> 500" >
                        <span *ngIf="!showMoreText">קרא עוד</span>
                        <span *ngIf="showMoreText">צמצם פסקה</span>
                        </button>
                    </div>
            </div>
        </div>
    </section>

<section id="gallery" >  
    <div class="container page-top " *ngIf="!(nofelDetails?.galleries.length ==1 && (nofelDetails?.galleries[0].pictureGallery.length == 0 || nofelDetails?.galleries[0].pictureGallery.length == 1))">
        <h2 title="אסופת געגועים">אסופת געגועים</h2>
        <div class="vidow">
            <div id="carouselVid" class="carousel slide" data-ride="carousel" >     
                <a *ngIf="nofelDetails?.videos?.length > 1" class="btn-floating" href="#carouselVid" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                <a *ngIf="nofelDetails?.videos?.length > 1" class="btn-floating" href="#carouselVid" data-slide="next"><i class="fa fa-chevron-right"></i></a>
             
            <div class="carousel-inner">
                <div *ngFor="let vid of nofelDetails?.videos; let first = first;"   [class.active]="first" class="carousel-item" >
                    <iframe  class="text-center w-100  d-block"
                    [src]="vid.youtubeLink | safe" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe> 
                  </div>
            </div>
        </div>
        </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="!(nofelDetails?.galleries.length ==1 && nofelDetails?.galleries[0].pictureGallery.length == 1)">
                            <nav>
                                <div class="nav nav-tabs nav-fill mb-2" id="nav-tab" role="tablist">
                                    <a *ngFor="let gallery of nofelDetails?.galleries  let i = index" (click) = "galleryActiveIndex = i " [class]="setNavClass(i)"  data-toggle="tab" [href]= "'#nav-' + i" role="tab" aria-controls="nav-home" aria-selected="true">{{gallery.title}} </a>
                                 </div>
                            </nav>
                            <div class="tab-content" id="nav-tabContent">
                                <div *ngFor="let gallery of nofelDetails?.galleries  let i = index" [class]="setGalleryClass(i)" id = "{{'#nav-' + i}}" role="tabpanel" aria-labelledby="nav-1-tab">
                                    <div class="container page-top">
                                        <div class="row">
                                            <div *ngFor="let pic of gallery.pictureGallery | slice:0:countOfShow" class="col-lg-3 col-md-4 col-6 thumb">
                                                                                          
                                                <a [href]="getPicPath(i ,gallery.id,pic.picLink)" class="fancybox" rel="ligthbox"  target="popup" >
                                                    <img  [src]= "getPicPath(i ,gallery.id,pic.picLink)" class="zoom img-fluid "  alt="" title="">
                                                   
                                                </a>
                                            </div>
                                            <div *ngIf="gallery.pictureGallery.length > 12" class="mb-3 font-weight-bold col-md-12">
                                                <a class="text-left" (click)="showOrHidden(gallery.pictureGallery)" *ngIf="!gallery.pictureGallery.showAll">הצג הכל</a>
                                                <a (click)="showOrHidden(gallery.pictureGallery)" *ngIf="gallery.pictureGallery.showAll">הסתר</a>
                                            </div>
                                         </div>
                                     </div>
                                 </div>
                              </div>
                         </div>
                     </div>
     </div>
  </section>

  <sectionn  *ngIf="nofelDetails?.moreTexts?.length > 0" >
      <h2  title="זכרונות">זכרונות</h2>
    <div id="accordion" *ngFor="let moreText of nofelDetails?.moreTexts  let i = index">
         <div class="card"  *ngFor="let text of moreText.text  let i = index">
            <div class="card-header" id = "{{'heading-' + i}}" >
              <h5 class="mb-0">
                <button class="btn btn-link" data-toggle="collapse"  attr.data-target="{{'#collapse-' + i}}"  aria-expanded="true" attr.aria-controls="{{'#collapse-' + i}}">
                  {{text.title}}
                </button>
              </h5>
            </div>
            <div id="{{'collapse-' + i}}"  class="collapse" aria-labelledby="{{'heading-' + i}}" data-parent="#accordion">
                <div class="card-body mt-4" [innerHTML]="text.text">
                    </div></div>
         </div>
     </div>
  </sectionn> 

  <section class="py-3 mt-2 add-candle-section" id="addcandle">
    <h2>חדר נרות</h2>
    <p>
        <button  class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            הדלק נר
        </button>
     </p>

     <div class="collapse " id="collapseExample">
        <div class="card card-body">
            <app-add-candle [nofelId] = id></app-add-candle>
        </div>
     </div>
     <div class="container">
        <section id="" class="py-3 mt-2 ">
            <div class=" text-right mb-3" *ngFor="let candle of candles$ | async | slice:0:cnadleCountOfShow">
                <app-candle  [candle] = candle></app-candle>
            </div>
            <div *ngIf="lengthCandle >5" class="mb-3 font-weight-bold col-md-12">
                <a class="text-left" (click)="showOrHiddenCandle()" *ngIf="!showAll">הצג הכל</a>
                <a (click)="showOrHiddenCandle()" *ngIf="showAll">הסתר</a>
            </div>
       </section>
     </div>
     </section>

     <app-share [title] = "sahreTitle" [img] = "shareImg"></app-share>

</div>

<div class="loadingBgDiv"  *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
</div>
