import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentPageComponent } from './content-page/content-page.component';
import { EventSignOnComponent } from './event-sign-on/event-sign-on.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HomeComponent } from './home/home.component';
import { NofelDetailsComponent } from './nofel-details/nofel-details.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { WithTheFamiliesComponent } from './with-the-families/with-the-families.component';

const routes: Routes = [
  {path:'' , component: HomeComponent},
  {path:'withthefamilies' ,component: WithTheFamiliesComponent},
  {path:'nofel/:id' , component: NofelDetailsComponent},
  {path:'contentpage/:id' , component: ContentPageComponent},
  {path:'eventsignon' , component: EventSignOnComponent},
  {path:'eventsignonfeedback/:successOrErr' ,component: FeedbackComponent },
  {path:'eventsignon/:eventid' , component: EventSignOnComponent},
  {path:'searchResults' ,component: SearchResultsComponent },
  {path:'**' , component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
