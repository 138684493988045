<div id="uc" class=" siteSection">

    <div id="ContainerUC_mainDiv" class="">

        <div id="ContainerUC_ctl00_mainDiv" class="col-lg-4">
            <span id="ContainerUC_ctl00_htmlSpan" class="text-justify">
                <div class="divLogo">
                    
                    <div [innerHTML]="media.text" *ngFor="let media of mediot | filterMediaPipe:4; ">

                    </div>
                    
                    <div *ngIf="networks">

                        <div class="social-networks">

                            <div>
                                <a *ngFor="let network of networks | filterTypePipe:1" href="{{network.link}}"
                                    class="{{network.class_}} icon_" title="{{network.title}}"><i
                                        class="{{network.fa_class}}"></i></a>
                            </div>
                            <div>
                                <a *ngFor="let network2 of networks | filterTypePipe:2" class="link_"
                                    href="{{network2.link}}">{{network2.title}}</a>
                            </div>


                        </div>

                    </div>

                </div>
            </span>
        </div>
    </div>
</div>