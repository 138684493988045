<div class="row candle">
    <div class="col-md-3">
        <img src="assets/img/candle.png" alt="הדלקת נר" title="הדלקת נר" class="img-fluid float-left">
    </div>
    <div class="col-md-9 align-self-center">
        <h2>הודלק על ידי {{candle.firstName}} {{candle.lastName}}</h2>
        <h5>בתאריך {{candle.insertDate | date: 'dd/MM/yyyy' }}</h5>
<p>{{candle.txt}} </p>
   </div>
 </div>
