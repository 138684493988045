import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';
declare var _iframeUrl: any;

@Component({
  selector: 'app-tekes-azkara',
  templateUrl: './tekes-azkara.component.html',
  styleUrls: ['./tekes-azkara.component.styl']
})

export class TekesAzkaraComponent implements OnInit {

  public mediot:any;
  public iframeUrl:string;

  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    this.iframeUrl=_iframeUrl;
    this.mediaService.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==2)))
      .subscribe(d => {
          this.mediot= d ;
      });
  }

}
