import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';

declare var _apiUrl: any ;
declare var _imgUrlPath: any;

@Component({
  selector: 'app-nofel-details',
  templateUrl: './nofel-details.component.html',
  styleUrls: ['./nofel-details.component.styl']
})
export class NofelDetailsComponent implements OnInit {

 public id: any;
 public nofelDetails: any;
 public candles$ : Observable<any>;
 public isAddCandle = true; 
 public urlPath = _imgUrlPath ;
 public showMoreText = false;
 public galleryActiveIndex =0;
 isLoading = true;
 countOfShow = 12;
 cnadleCountOfShow = 5;
 showAll = false;
 lengthCandle= 0;
 sahreTitle: any;  shareImg: any;
 shouldBeOpen= false;
 benText = '';
 nofloTxt = "בנופלו";
 nafalTxt="נפל";
  constructor(private router: Router , private route: ActivatedRoute,private http: HttpClient ,private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
   this.id = this.route.snapshot.paramMap.get('id');

   this.urlPath = _imgUrlPath + this.id.padStart(6, '0') + '/' ;

   this.http.get(_apiUrl + 'NofelDetails/' +this.id )
  .subscribe(n => {this.nofelDetails = n ; this.isLoading=false;
    if(this.nofelDetails.details == null)
    {
      this.router.navigate(['/']); 
    } 
    console.log(this.nofelDetails);
    if(this.nofelDetails?.details.benBat == 'בת'){
      this.nafalTxt = "נפלה";
      this.nofloTxt = "בנופלה";
    }
    if(this.nofelDetails?.details.fatherName || this.nofelDetails?.details.motherName)
    {
    this.benText = this.nofelDetails?.details.benBat+ ' ';
    if(this.nofelDetails?.details.fatherName)
    {
      this.benText +=  this.nofelDetails?.details.fatherName ;}
      if(this.nofelDetails?.details.motherName){
     this.benText += ' ו' +  this.nofelDetails?.details.motherName; }
    
  }
  this.setMeta();  this.sahreTitle = 'לזכרם  - ' + this.nofelDetails.details.firstName + ' ' + this.nofelDetails.details.lastName; });
     this.candles$ =  this.http.get(_apiUrl + 'Candles/' +this.id );   
     this.candles$.subscribe(c => this.lengthCandle = c.length) ;



  }
  setMeta() {
    this.titleService.setTitle('לזכרם -  - ' + this.nofelDetails.details.firstName + ' ' + this.nofelDetails.details.lastName);
    this.metaTagService.updateTag(
      { name: 'description', content: this.nofelDetails.details.text }
    );

    this.metaTagService.addTags([
      { name: 'keywords', content: this.nofelDetails.details.firstName + ' ' + this.nofelDetails.details.lastName },
      { name: 'og:title', content: 'לזכרם -  - ' + this.nofelDetails.details.firstName + ' ' + this.nofelDetails.details.lastName },
      { name: 'og:image', content: this.urlPath + this.nofelDetails?.details.picLink },
      { name: 'og:description', content:  this.nofelDetails.details.firstName + ' ' + this.nofelDetails.details.lastName },
      { name: 'og:url' , content: window.location.href }
    ]);
  
  }

  getPicPath (i: number , connectId: object , link: object ): string{
if(i==0){
return _imgUrlPath + link ;
}
else{
return _imgUrlPath.replace('PictureGallery/' , '') +  link;
}
  }
  setGalleryClass(i:number) : string{
    var cl = 'tab-pane fade'
    if(i == this.galleryActiveIndex )
    {
      cl += ' show active'
    }
    return cl;
  }
  setNavClass(i:number) :string{
    var cl = 'nav-item nav-link ';
    if(i == this.galleryActiveIndex)
    {
      cl+= ' active';
    }
    return cl;
  }
  addCandleHref():string{
    return window.location.href.replace('#addcandle' , '')+'#addcandle';
  }

  onImgError(event){
    if(this.nofelDetails?.details.benBat == 'בת')
    {
      event.target.src = 'assets/img/woman.png';
    }
    else{
      event.target.src = 'assets/img/man.png';
    }

   }

   addCandleClick(){
              
    var addCandleControl: HTMLElement = document.querySelectorAll('.add-candle-section')[0] as HTMLElement;


    window.scroll({
      top: this.getTopOffset(addCandleControl),
      left: 0,
      behavior: "smooth"
    });
    this.shouldBeOpen = true;
   }

   private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }     

  public showOrHidden(gallery:any) {
    console.log(gallery);
    if (gallery.showAll)
      this.countOfShow = 12;
    else
      this.countOfShow = gallery.length;
   gallery.showAll = !gallery.showAll;
  }

  public showOrHiddenCandle() {
    if (this.showAll)
      this.cnadleCountOfShow = 5;
    else
      this.cnadleCountOfShow =  this.lengthCandle; 
    this.showAll = !this.showAll;
  }

  Add3(txt) : string{
    return txt  + '...';
  }

}
