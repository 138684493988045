import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

declare var _apiUrl: any ;
declare var _mediaPath: any;

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.styl']
})
export class ContentPageComponent implements OnInit {

id: any;
 public page: any;
 public mediaPath = _mediaPath;
 public bootomCarusle: Array<any>;
 numBottomGalleryInRow = 3;
 isLoading = true;
 sahreTitle: any;  shareImg: any;
  constructor(private router: Router , private route: ActivatedRoute,private http: HttpClient, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if(window.innerWidth < 600)
    {
      this.numBottomGalleryInRow = 1;
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.http.get(_apiUrl + 'Page/'+ this.id )
    .subscribe(n => {this.page = n ; 
      if(this.page.page == null)
      {
        this.router.navigate(['/']); 
      }
      console.log(this.page);
      this.sahreTitle = this.page.page.title;
      this.shareImg = window.location.origin + '/src/assets/img/shareImg.jpg';
      this.setCarusle();
      this.isLoading= false;
      this.titleService.setTitle(this.page.page.title);
      this.metaTagService.updateTag(
        { name: 'description', content: this.page.page.description }
      );

      this.metaTagService.addTags([
        { name: 'keywords', content: this.page.page.keyword },
        { name: 'og:title', content: this.page.page.title },
        { name: 'og:image', content: window.location.origin + '/src/assets/img/shareImg.jpg' },
        { name: 'og:description', content: this.page.page.description },
        { name: 'og:url' , content: window.location.href }
      ]);
    
    }, error => {this.isLoading = false; console.log(error); 
     }
    );  

  
  }

  setCarusle(){
    var isMobile = (window.innerWidth < 600);
    this.page.bottomGallery = this.page?.bottomGallery.filter(item => (item.onlyMobile == isMobile && item.onlyDeskTop !=isMobile) || (!item.onlyMobile && ! item.onlyDeskTop) );
    this.page.topGallery = this.page?.topGallery.filter(item => (item.onlyMobile == isMobile && item.onlyDeskTop !=isMobile) || (!item.onlyMobile && ! item.onlyDeskTop) );
  

    this.bootomCarusle = new Array();
    for (let i = 0; i < this.page?.bottomGallery?.length / this.numBottomGalleryInRow ; i ++) {
        this.bootomCarusle[i] = this.page.bottomGallery.slice(i * this.numBottomGalleryInRow,(i+1) * this.numBottomGalleryInRow);
     
    } 
  }

  getClass(index: any) : string {
    var cl = 'col-md-4';
    if(index > 0 )
    cl += ' clearfix d-none d-md-block';
    return cl;
   }

}
