import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

declare var _apiUrl: any;


@Component({
  selector: 'app-reshatot-chevratiot',
  templateUrl: './reshatot-chevratiot.component.html',
  styleUrls: ['./reshatot-chevratiot.component.styl']
})
export class ReshatotChevratiotComponent implements OnInit {
  
  public networks: any;
  public mediot:any;

  constructor(private http: HttpClient ,public mediaService: MediaSectionsService) { }

  ngOnInit(): void {    
    this.mediaService.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==19)))
    .subscribe(d => {
        this.mediot= d ;
    });
    
    this.http.get(_apiUrl + 'Network')
      .subscribe(n => {      
        this.networks = n;
      });

      
  }

}
