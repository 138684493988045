<a  *ngIf="!isSearchResult" class="nofel"  [routerLink]="['/nofel', nofel.id]">

        <img class="img-fluid  w-75 mb-3 height-200 " [src]="(urlPath + nofel.picLink)"
        (error) ="onImgError($event)" 
        alt="{{nofel.firstName + ' '+ nofel.lastName}}"             
        title= "{{nofel.firstName  + ' '+ nofel.lastName}}">
     
        <h6>{{nofel.dargaName}}</h6>
        <h3>{{nofel.firstName}} {{nofel.lastName}}</h3>    
        <h6>{{nofel.dateLeda | date: 'yyyy'}} -  {{nofel.dateNefila | date: 'yyyy'}}</h6>  

</a>

<div class="row " *ngIf="isSearchResult">
        <div class="col-4 col-md-4">
                <img class="w-100" [src]="(urlPath + nofel.picLink)"
                (error) ="onImgError($event)"
                alt="{{nofel.firstName + ' '+ nofel.lastName}}"             
                title= "{{nofel.firstName  + ' '+ nofel.lastName}}" >
        </div>
        <div class="col-8 col-md-8 align-self-center">
            <h5 title="שם החלל">
                    <span class="darga">{{nofel.dargaName}}</span>
                    {{nofel.firstName}} {{nofel.lastName}} </h5>
            <h6>מ.א {{nofel.ishi}} </h6>
            <h6>{{nafalTxt}} ביום {{nofel.nefila_He_Day}} {{nofel.nefila_He_Month}} שנת {{nofel.nefila_He_Year}} </h6>
            <h5>{{nofel.dateNefila | date: 'dd/MM/yyyy'}}</h5>
         <a href="#" [routerLink]="['/nofel', nofel.id]">לדף החלל</a>
        </div>
    </div>
