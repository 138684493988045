import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contact } from '../entities/Contact';
import { MediaSectionsService } from '../services/mediaSectionsService';
import { map } from 'rxjs/operators';
import { isError } from 'util';

declare var _apiUrl: any;
declare var _CaptchaKey: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.styl']
})
export class ContactComponent implements OnInit {

  @ViewChild(RecaptchaComponent) recaptcha1: RecaptchaComponent;

  fgInputs = new FormGroup({
    LastName: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    FirstName: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Unit: new FormControl('', [Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Nofel: new FormControl(null, [Validators.required]),
    Subject: new FormControl('', [Validators.required, Validators.maxLength(5000), Validators.pattern('^[^~`^<>]+$')]),
    Phone: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^\\+?(972|0)(\\-)?0?(([23489]{1}\\d{7})|[5]{1}\\d{8}|[7]{1}\\d{8})$')]),
    Mail: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern('\\w+([-+.\']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*')]),
    Kirva: new FormControl(null, [Validators.required]),
    ContactType: new FormControl(null, [Validators.required])
  }
  );

  public kirva;
  public noflim;
  public categories;
  contact: Contact;
  contactSucsses = false;
  contactErr = false;
  isSubmit = false;
  public secret: string = _CaptchaKey;
  searchResult$: any;
  public mediot: Observable<any>;
  generalData: any;
  isLoading = false;

  constructor(private http: HttpClient, public mediaService: MediaSectionsService) {

  }

  ngOnInit(): void {
    this.mediaService.dataMedia.pipe(map(items => items.filter(item => item.sectionId == 15)))
      .subscribe(d => {
        this.mediot = d;
      });

    this.http.get(_apiUrl + 'ContactGeneralData')
      .subscribe(n => {
        this.generalData = n;
        this.kirva = this.generalData.kirva;
        this.categories = this.generalData.categories;
        this.noflim = this.generalData.noflim;
      });

  }

  send() {
    console.log('send');
    this.isSubmit = true;
    this.contactErr = false;
    this.contactSucsses = false;

    if (this.fgInputs.valid) {
      this.isLoading = true;
      this.recaptcha1.reset();
      this.recaptcha1.execute();
    }
  }

  public submitWithCaptcha(captchaResponse: string) {
    this.contact = new Contact();
    this.contact.GoogleResponseToken = captchaResponse;
    this.contact.FirstName = this.fgInputs.get('FirstName').value;
    this.contact.LastName = this.fgInputs.get('LastName').value;
    this.contact.Phone = this.fgInputs.get('Phone').value;
    this.contact.Email = this.fgInputs.get('Mail').value;
    this.contact.Subject = this.fgInputs.get('Subject').value;
    this.contact.Unit = this.fgInputs.get('Unit').value;
    this.contact.CategoryID = this.fgInputs.get('ContactType').value;
    this.contact.KirvaId = this.fgInputs.get('Kirva').value;
    this.contact.NofelId = this.fgInputs.get('Nofel').value;

    if (this.contact.NofelId > 0 && captchaResponse) {

      this.http.post(_apiUrl + 'Contact', this.contact)
        .subscribe(c => { this.contactSucsses = true; this.contactErr = false; this.isLoading = false; this.fgInputs.reset(); this.isSubmit = false; },
          err => { console.log(err); this.contactErr = true; this.contactSucsses = false; this.isLoading = false; this.fgInputs.reset(); this.isSubmit = false; })
    }
  }




}
