import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { MishtatefEvent } from '../entities/MishtatefEvent';
import { InputsValidators } from '../entities/Validators';

declare var _apiUrl: any;
declare var _CaptchaKey: any;

@Component({
  selector: 'app-event-mishtatef',
  templateUrl: './event-mishtatef.component.html',
  styleUrls: ['./event-mishtatef.component.styl']
})
export class EventMishtatefComponent implements OnInit {

  @Output("destroy_") destroy_: EventEmitter<EventMishtatefComponent> = new EventEmitter<EventMishtatefComponent>();
  @Output("check_in") check_in: EventEmitter<EventMishtatefComponent> = new EventEmitter<EventMishtatefComponent>();

  @ViewChild(RecaptchaComponent) recaptcha2: RecaptchaComponent;
  
  
  @Input() kirvaList: any[];
  isChecked = false;
  signOnErr = false;
  signOnSucsses = false;
  isLoading = false;
  mishtatefEvent: MishtatefEvent;
  public secret: string = _CaptchaKey;
  nofesh = false;
  currentId: number;
  showHide= true;
  minDate = new Date(1900 , 1 ,1);
  maxDate = new Date();
  sizes;

  
  constructor(private http: HttpClient ,private validators:InputsValidators,private router: Router) { }

  fgInputsMishtatfim = new FormGroup({
    LastName_M: new FormControl('',  [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    FirstName_M: new FormControl('', [Validators.required, Validators.maxLength(20), Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Kirva_M: new FormControl(null, []),
    Age_M: new FormControl(null, [Validators.min(0), Validators.max(120)]),
    DateOfBirth_M: new FormControl(null, null),
    SizeOfShirt_M: new FormControl(null, null),
    GreenCharacter_M: new FormControl(null, null),

  });

  ngOnInit(): void {

    this.fgInputsMishtatfim.get('GreenCharacter_M').setValue(null);

    this.mishtatefEvent = new MishtatefEvent();
    this.showHide=true;

    this.http.get(_apiUrl + 'Sizes')
    .subscribe(n => {
      this.sizes = n;//[{key:'2',val:'5'},{key:'3',val:'5'}]
    })
  
  }

  public send(id){
    console.log('send');
    //this.isSubmit = true;
    this.signOnErr = false;
    this.signOnSucsses = false;

    this.mishtatefEvent.RoshemId=id;

    if (this.fgInputsMishtatfim.valid) {
      this.isLoading = true;
      this.recaptcha2.reset();
      this.recaptcha2.execute();
    }
  }

  public submitWithCaptcha(captchaResponse: string) {
    debugger;
    this.mishtatefEvent.GoogleResponseToken = captchaResponse;
    this.mishtatefEvent.FirstName = this.fgInputsMishtatfim.get('FirstName_M').value;
    this.mishtatefEvent.LastName = this.fgInputsMishtatfim.get('LastName_M').value;
    this.mishtatefEvent.KirvaId = this.fgInputsMishtatfim.get('Kirva_M').value ?? 0;
    this.mishtatefEvent.Age = this.fgInputsMishtatfim.get('Age_M').value ?? 0;
    this.mishtatefEvent.DateOfBirth=this.fgInputsMishtatfim.get('DateOfBirth_M').value;
    this.mishtatefEvent.SizeOfShirt=this.fgInputsMishtatfim.get('SizeOfShirt_M').value;
    this.mishtatefEvent.GreenCharacter=this.fgInputsMishtatfim.get('GreenCharacter_M').value;
    

    if (captchaResponse) {
      debugger;
      this.http.post(_apiUrl + 'EventMishtatef', this.mishtatefEvent)
        .subscribe(n => { 
          debugger;
          this.signOnSucsses = true; 
          this.signOnErr = false; 
          this.isLoading = false; 
          this.fgInputsMishtatfim.reset(); 
          this.isChecked = false; 
          this.router.navigate(['eventsignonfeedback/1']);

        },
          err => { 
            this.router.navigate(['eventsignonfeedback/0']);
            console.log(err); this.signOnErr = true; this.signOnSucsses = false; this.isLoading = false; this.fgInputsMishtatfim.reset(); this.isChecked = false; })
    }
  }
  public checkIn(){
    debugger;
    if (this.fgInputsMishtatfim.valid) {
    this.isChecked = false;
    this.check_in.emit()
    }
    else
    this.isChecked=true;
  }
  public delete(){
    this.destroy_.emit();
    
  }

}
