
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'MyFormatDatePipe',
    pure: false
})
export class MyFormatDatePipe implements PipeTransform {
    transform(_date: string): any {
        if (!_date ) {
            return _date;
        }
        var d = new Date(_date);
        var a= d.getDate()+"/"+(d.getMonth()+1);
        return a;
    }
}