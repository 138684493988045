import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterTypePipe',
    pure: false
})
export class filterTypePipe implements PipeTransform {
    transform(items: any[], filter: number): any {
        if (!items || !filter) {
            return items;
        }
        
        var a=items.filter(item => item.type_id == filter);
      //  debugger;
        return a;
    }
}