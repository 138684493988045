import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoflimToDayComponent } from './noflim-to-day/noflim-to-day.component';
import { NofelComponent } from './nofel/nofel.component';
import { HttpClientModule } from "@angular/common/http";
import { SearchComponent } from './search/search.component';
import {RecaptchaModule} from 'ng-recaptcha';
import { ContactComponent } from './contact/contact.component';
import { NofelDetailsComponent } from './nofel-details/nofel-details.component';
import { HomeComponent } from './home/home.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FAQComponent } from './faq/faq.component';
import { CandleComponent } from './candle/candle.component';
import { AddCandleComponent } from './add-candle/add-candle.component';
import { CandleSectionComponent } from './candle-section/candle-section.component';

import { YizkorTitleComponent } from './yizkor-title/yizkor-title.component';
import { filterMediaPipe } from './pipes/filterMediaPipe';
import { SafePipe } from './pipes/safePipe';
import { TekesAzkaraComponent } from './tekes-azkara/tekes-azkara.component';
import { LezichramComponent } from './lezichram/lezichram.component';
import { MishpachaComponent } from './mishpacha/mishpacha.component';

import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchResults } from './entities/SearchResults';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import {MatDatepickerModule,MatRadioModule ,MatNativeDateModule,MatFormFieldModule,MatInputModule,  MAT_DATE_LOCALE} from '@angular/material'
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { WithTheFamiliesComponent } from './with-the-families/with-the-families.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { ShareComponent } from './share/share.component'

import { MishpachaTitleComponent } from './mishpacha-title/mishpacha-title.component'
import { MishpachaMeidaKlaliComponent } from './mishpacha-meida-klali/mishpacha-meida-klali.component';
import { MishpachaHodaotComponent } from './mishpacha-hodaot/mishpacha-hodaot.component';
import { MishpachaEventsComponent } from './mishpacha-events/mishpacha-events.component'
import { MyFormatDatePipe } from './pipes/datePipe';
import { MyFormatTimePipe } from './pipes/timePipe';
import { ReshatotChevratiotComponent } from './reshatot-chevratiot/reshatot-chevratiot.component';
import { MishpachaHartzaotComponent } from './mishpacha-hartzaot/mishpacha-hartzaot.component';
import { ClipboardModule } from 'ngx-clipboard';
import { filterTypePipe } from './pipes/filterTypePipe';
import { YizcorTextComponent } from './yizcor-text/yizcor-text.component';
import { EventSignOnComponent } from './event-sign-on/event-sign-on.component';
import { InputsValidators } from './entities/Validators';
import { CommonModule } from '@angular/common';
import { EventMishtatefComponent } from './event-mishtatef/event-mishtatef.component';
import { FeedbackComponent } from './feedback/feedback.component';

@NgModule({
  declarations: [
    AppComponent,
    NoflimToDayComponent,
    NofelComponent,
    SearchComponent,
    ContactComponent,
    NofelDetailsComponent,
    HomeComponent,
    FAQComponent,
    CandleComponent,
    AddCandleComponent,
    CandleSectionComponent,
    SearchResultsComponent,
    NavBarComponent,
    YizkorTitleComponent,
    filterMediaPipe,
    filterTypePipe,
    SafePipe, MyFormatDatePipe, MyFormatTimePipe,
    TekesAzkaraComponent,
    LezichramComponent,
    MishpachaComponent,  
    SearchResultsComponent, 
    WithTheFamiliesComponent, 
    ContentPageComponent, 
    ShareComponent,

    SearchResultsComponent, WithTheFamiliesComponent, MishpachaTitleComponent,
    SearchResultsComponent, WithTheFamiliesComponent, ContentPageComponent, MishpachaMeidaKlaliComponent, MishpachaHodaotComponent, MishpachaEventsComponent, ReshatotChevratiotComponent, MishpachaHartzaotComponent, YizcorTextComponent, EventSignOnComponent,EventMishtatefComponent, FeedbackComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    FormsModule, ReactiveFormsModule,NgSelectModule ,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule ,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    ClipboardModule,
    CommonModule
  ],
  providers: [SearchResults, InputsValidators,
   { provide: MAT_DATE_LOCALE, useValue: 'he-IL' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
