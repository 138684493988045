<section id="questions" class="bg-faded faq">
    <div class="container ">
        <h2 class="display-4 text-muted text-center ">שאלות ותשובות</h2>
        <div>

            <ul class="row ">
                <ng-container *ngIf="faqies">
                    <!-- class="card pt-4 pb-4 row col-md-5 col-xs-12 bg-white mt-2 ml-2 mb-2 hight-70" -->
                    <div (click)="faq.show=!faq.show"
                        class=" col-md-6 col-xs-12"
                        *ngFor="let faq of faqies | slice:0:countOfShow">
                        <div class="card-header" [class]=" faq.show ? 'answer_body' : '' " >
                            <i *ngIf="!faq.show" class="fa fa-question"></i>
                            <i *ngIf="faq.show" class="fa fa-info-circle"></i>
                            <h5 class=" col-10" *ngIf="!faq.show ">{{faq.question_body}}</h5>
                            <h5 class="col-10" *ngIf="faq.show">{{faq.answer_body}}</h5>
                        </div>
                    </div>
                </ng-container>

            </ul>
            <div *ngIf="lengthFAQ > 8" class="mb-3 font-weight-bold">
                <a class="text-left" (click)="showOrHidden()" *ngIf="!showAll">הצג הכל</a>
                <a (click)="showOrHidden()" *ngIf="showAll">הסתר</a>
            </div>

        </div>
    </div>
</section>