import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

declare var _apiUrl: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.styl']
})
export class FAQComponent implements OnInit {

  constructor(private http: HttpClient) {
  }

  public faqies: any;
  public countOfShow: number;
  public lengthFAQ: number;
  public showAll: boolean;

  ngOnInit(): void {

    this.countOfShow = 8;
    
    this.http.get(_apiUrl + 'FAQ')
      .subscribe(f => {
        this.faqies = f;
        this.lengthFAQ = this.faqies.length;
      });


  }

  public showOrHidden() {
    if (this.showAll)
      this.countOfShow = 8;
    else
      this.countOfShow = this.lengthFAQ;
    this.showAll = !this.showAll;
  }


}
