import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.styl']
})
export class ShareComponent implements OnInit {

  @Input() title: any;
  @Input() img: any;
  isCopy = false;
  constructor() { }

  ngOnInit(): void {
  }


  getLink() {
    console.log(this.img);
    return window.location.href;
  }

  goFacebook() {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(this.getLink()
   +'&title='+ this.title +'&p[images][0]=' + this.img), '_blank');
  }

  goTwitter() {
    window.open('https://twitter.com/home?status=' + this.title +  encodeURI(this.getLink()), '_blank');
  }

  goWhatsapp() {        
    window.open('whatsapp://send?text=' +  this.title + "  "  +encodeURI(this.getLink() +'&p[images][0]=' + this.img), '_blank');
  }

  showNotifOnCopy(){
  }

  copy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.getLink();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopy = true;
    setTimeout(()=>{
      this.isCopy=false;
    },5000);
  }

}
