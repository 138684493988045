<ng-container *ngIf="showHide">
    <form id="mishtatefEvent" [formGroup]="fgInputsMishtatfim">
        <div class="row">



            <div class="col-md-3">
                <div class="form-group">
                    <label for="LastName_M" class="float-right required">שם פרטי</label>
                    <input type="text" class="form-control" formControlName="FirstName_M" maxlength="20">
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('FirstName_M').hasError('pattern')"> לא
                        תקין
                    </span>
                    <span class="errValid"
                        *ngIf="isChecked && fgInputsMishtatfim.get('FirstName_M').hasError('required')">
                        שדה חובה </span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName" class="float-right required">שם משפחה</label>
                    <input type="text" class="form-control" formControlName="LastName_M" maxlength="20">
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('LastName_M').hasError('pattern')"> לא תקין
                    </span>
                    <span class="errValid"
                        *ngIf="isChecked && fgInputsMishtatfim.get('LastName_M').hasError('required')">
                        שדה חובה </span>

                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName" class="float-right">סוג קירבה</label>
                    <ng-select class="lg-mb-20 xs-mb-15 " [items]="kirvaList" bindLabel="val" bindValue="key"
                        formControlName="Kirva_M" title="בחר  קירבה" notFoundText="לא נמצאו תוצאות"
                        [clearable]="true" placeholder="בחר קירבה">
                    </ng-select> 
                    <!-- <span class="errValid"
                        *ngIf="isChecked && fgInputsMishtatfim.get('Kirva_M').hasError('required')"> שדה חובה </span> -->

                </div>
            </div>
            <!-- <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName" class="float-right required">תעודת זהות</label>
                    <input type="text" class="form-control" formControlName="TZ_M" maxlength="20">
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('TZ_M')?.hasError('pattern')" translate> לא
                        תקין </span>
                    <span class="errValid" *ngIf="isChecked && fgInputsMishtatfim.get('TZ_M')?.hasError('required')"
                        translate>
                        שדה חובה </span>
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('TZ_M')?.hasError('validateTz')" translate> לא
                        תקין
                    </span>

                </div>
            </div> -->
            <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName" class="float-right">גיל</label>
                    <input type="number" min="0" max="120" class="form-control" formControlName="Age_M">
                    <span class="errValid" *ngIf=" fgInputsMishtatfim.get('Age_M').hasError('max')"> לא תקין </span>
                    <span class="errValid" *ngIf=" fgInputsMishtatfim.get('Age_M').hasError('min')"> לא תקין </span>

                    <!-- <span class="errValid" *ngIf="isSubmit && fgInputsMishtatfim.get('Age_M').hasError('required')">
                            שדה חובה </span> -->

                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="firstName" class="float-right">תאריך לידה</label>
                    <mat-form-field>

                        <input [min]="minDate" [max]="maxDate" matInput [matDatepicker]="fromPicker"
                            formControlName="DateOfBirth_M">
                        <mat-datepicker-toggle maxSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                    <!-- <input type="text" class="form-control" formControlName="DateOfBirth_M" maxlength="20">
                        <span class="errValid" *ngIf="fgInputsMishtatfim.get('DateOfBirth_M').hasError('pattern')"> לא
                            תקין
                        </span>
                        <span class="errValid"
                            *ngIf="isSubmit && fgInputsMishtatfim.get('DateOfBirth_M').hasError('required')">
                            שדה חובה </span> -->

                </div>
            </div>

            <div class="col-md-3" [hidden]="!nofesh">
                <div class="form-group">
                    <label for="firstName" class="float-right">מידת חולצה</label>
                    <!-- <input type="text" class="form-control" formControlName="SizeOfShirt_M" maxlength="20"> -->
                    <ng-select class="lg-mb-20 xs-mb-15" [items]="sizes" bindLabel="val" bindValue="key"
                        formControlName="SizeOfShirt_M" title="בחר מידה " notFoundText="לא נמצאו תוצאות" [clearable]="true"
                        placeholder="בחר מידה">
                    </ng-select>
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('SizeOfShirt_M').hasError('pattern')"> לא
                        תקין
                    </span>
                    <span class="errValid"
                        *ngIf="isChecked && fgInputsMishtatfim.get('SizeOfShirt_M').hasError('required')">
                        שדה חובה </span>

                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">

                    <!-- <label for="firstName" class="float-right col-md-12 required">קיים תו ירוק</label>
                   
                    <label class="col-md-3">
                        <input type="radio"  [value]="true" RepeatDirection="Horizontal"
                            RepeatLayout="Flow" formControlName="GreenCharacter_M">כן
                        
                    </label>

                    <label class="col-md-9 pr-0">
                        <input type="radio" [value]="false"  formControlName="GreenCharacter_M"
                            RepeatDirection="Horizontal" RepeatLayout="Flow" >לא
                    </label>
                    <span class="errValid"
                                *ngIf="isChecked && fgInputsMishtatfim.get('GreenCharacter_M').hasError('required')">
                                שדה חובה </span> -->


                    <!-- <label for="firstName" class="float-right">קיים תו ירוק</label>
                    <input type="checkbox" class="form-control" [checked]="fgInputsMishtatfim.get('GreenCharacter_M')"
                        formControlName="GreenCharacter_M">
                    <span class="errValid" *ngIf="fgInputsMishtatfim.get('GreenCharacter_M').hasError('pattern')">
                        לא תקין
                    </span> -->
                    <!-- <span class="errValid"
                            *ngIf="isSubmit && fgInputsMishtatfim.get('GreenCharacter_M').hasError('required')">
                            שדה חובה </span> -->

                </div>
            </div>
            <div class="col-md-1 row align-items-center">
                <a title="מחק" (click)="delete()" role="button" class="card-block customColor">
                    <!-- <i class="bi bi-trash-fill"></i> -->
                    <span>מחק</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                        <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                </a>

            </div>
            <div class="col-md-1  row align-items-center">
                <a class=" card-block customColor" title="הוספה" (click)="checkIn()" role="button">
                    <!-- <i class="bi bi-check-lg"></i> -->
                    <span>שמור</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check-lg" viewBox="0 0 16 16">
                        <path
                            d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
                    </svg>
                </a>

            </div>
        </div>
        <div [hidden]="true" class="col-md-12 ">
            <div class="form-group">
                <button type="button" class="btn btn-outline-warning float-left">שלח/י</button>
            </div>
        </div>
    </form>


</ng-container>
<re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}">
</re-captcha>