import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchResults } from '../entities/SearchResults';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.styl']
})
export class SearchResultsComponent implements OnInit {
  isLoading =false;
  isNoResult = false;
  isIron = "false";
  constructor(public searchReasult : SearchResults , private router: Router, private route: ActivatedRoute  , private titleService: Title, private metaTagService: Meta) { }

  ngOnInit(): void {
    if(this.searchReasult.searchResult$ == null)
    {
      this.router.navigate(['/']);
    }
    // if(this.searchReasult.searchResult$[0].SibatMavetID == 10641){
    //   this.isIron = true;
    // }
    this.isIron = this.route.snapshot.paramMap.get('isIron');
    this.titleService.setTitle('לזכרם - תוצאות חיפוש');
    this.metaTagService.updateTag(
      { name: 'description', content: 'לזכרם תוצאות חיפוש' }
    );
    
   
  }

  search(isNoResult : boolean){
   this. isNoResult = isNoResult;
   }

}
