<img *ngIf="isIron == 'true'" src="assets\img\banner-1.svg" style="width: 100%; margin-top: -61px;">
<h1 *ngIf="isIron == 'false'" class="text-center" title="תוצאות חיפוש">תוצאות חיפוש</h1>
<h1 *ngIf="isIron == 'true'" class="text-center" title="תוצאות חיפוש"
style="color: #FFF;
text-align: right;
font-family: Rubik;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.9px;">חללי משטרת ישראל ”חרבות ברזל”</h1>
<section id="search" class="text-center p-5">
    <div class="container">
        <app-search (searchResultEvent) ="search($event)" [isSearchResult]= true></app-search>
     </div>
</section>
    
<div class="container">
    <section id="" class="py-3 mt-5 row">
        <div *ngIf="isNoResult">
            לא נמצאו תוצאות חיפוש
        </div>
        <div *ngFor="let nofel of   searchReasult.searchResult$| async" class=" text-right mb-3 col-md-6 row">
            <app-nofel  [nofel] = nofel [isSearchResult] = true></app-nofel>
       </div>
     </section>
</div>

<div class="loadingDiv"  *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
</div>



<!--<div class="searchResult">
    <ul>
        <li *ngFor="let nofel of   searchReasult.searchResult$| async" class="col-md-3">   
            <app-nofel  [nofel] = nofel></app-nofel>
        </li>
      </ul>

</div>-->
