import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

@Component({
  selector: 'app-mishpacha-hartzaot',
  templateUrl: './mishpacha-hartzaot.component.html',
  styleUrls: ['./mishpacha-hartzaot.component.styl']
})
export class MishpachaHartzaotComponent implements OnInit {

  public mediot: any;
  public lengthHartzaot:number;
  public numBottomGalleryInRow = 3;
  public rows:Array<any>;

  constructor(public mediaService: MediaSectionsService) { }

  ngOnInit(): void {
    if(window.innerWidth < 600)
    {
      this.numBottomGalleryInRow = 1;
    }
    this.mediaService.dataMedia
      .pipe(map(items => items.filter(item => item.sectionId == 14)))
      .subscribe(d => {
        this.mediot = d;
        // this.lengthHartzaot=this.mediot.length;
       this.setRows();
      
      });
  }

  setRows() {
    ;
    var numRow= this.mediot.length/this.numBottomGalleryInRow;
    if(numRow % 1)
      numRow+=1;
    numRow=Math.floor(numRow/1);
    //this.rows= new Array(Math.floor(numRow/1));
    this.rows = new Array();
    for (let i = 0; i < numRow ; i ++) {
        this.rows[i] = this.mediot.slice(i * this.numBottomGalleryInRow,(i+1) * this.numBottomGalleryInRow);
     
    } 
  }

}
