import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

declare var _apiUrl: any ;

@Component({
  selector: 'app-noflim-to-day',
  templateUrl: './noflim-to-day.component.html',
  styleUrls: ['./noflim-to-day.component.styl']
})
export class NoflimToDayComponent implements OnInit {

  private noflimToDay$: Observable<any>;
  public noflimFilter : any[];
  public noflimAll : any[];
  public noflimFilterCarusle: Array<any>;
  public noflimFilterCarusleFirst: Array<any>;
  numNoflimInRow = 3;
  public mediot:Observable<any>;
  isFilter = false;
  isFilterHe = false;

  constructor(private http: HttpClient ,  public mediaService: MediaSectionsService) {    
  }

  
  ngOnInit(): void {   

    this.mediaService.dataMedia
    .pipe(map(items => items.filter(item => item.sectionId == 9)))
    .subscribe(d => {
      this.mediot = d;    
    });
    if(window.innerWidth < 600)
    {
      this.numNoflimInRow = 3;
    }
    this.noflimToDay$ = this.http.get(_apiUrl + 'NoflimToDay' );  
    this.noflimToDay$.subscribe(n => {this.noflimFilter = n ; this.noflimAll = n ; this.setCarusle();});
  
  } 

  setCarusle(){
    this.noflimFilterCarusle = new Array();
    this.noflimFilterCarusleFirst = new Array();
    for (let i = 0; i < this.noflimFilter.length / this.numNoflimInRow ; i ++) {
    
      if(i == 0 ){
        this.noflimFilterCarusleFirst[i] = this.noflimFilter.slice(i * this.numNoflimInRow,(i+1) * this.numNoflimInRow);
      }
      else{
      this.noflimFilterCarusle[i -1] = this.noflimFilter.slice(i * this.numNoflimInRow,(i+1) * this.numNoflimInRow);
      }
    } 
  }

  onlyToDay(){   
    this.isFilter= !this.isFilter;
    this.isFilterHe=false;
    if(this.isFilter){
    this.noflimFilter = this.noflimAll.filter(n => n.isToDay === true);    
   }
    else{
      this.noflimFilter = this.noflimAll;
    }
    this.setCarusle();
  }

  onlyHebrewDay(){
    this.isFilterHe= !this.isFilterHe;
    this.isFilter = false;
    if(this.isFilterHe){
    this.noflimFilter = this.noflimAll.filter(n => n.isHebrewDay === true);  }
    else{
      this.noflimFilter = this.noflimAll;
    }
    this.setCarusle();
  }
  all(){
    this.noflimFilter = this.noflimAll;  
    this.setCarusle();
    this.isFilter= false;
  }




}