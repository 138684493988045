import { validateVerticalPosition } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Candle, CandleRequest } from '../entities/Candle';


declare var _apiUrl: any ;
declare var _CaptchaKey: any;

@Component({
  selector: 'app-add-candle',
  templateUrl: './add-candle.component.html',
  styleUrls: ['./add-candle.component.styl']
})

export class AddCandleComponent implements OnInit {
  @ViewChild(RecaptchaComponent) recaptcha1: RecaptchaComponent;

  fgInputs = new FormGroup({
    LastName: new FormControl('', [Validators.maxLength(20), Validators.required, Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    FirstName: new FormControl('', [Validators.maxLength(20),  Validators.required, Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Text: new FormControl('' , [Validators.maxLength(500), Validators.required, Validators.pattern('^[^~`^<>]+$')]),
    isSubmit: new FormControl('0'),
    reset: new FormControl('1')
  }
);
  candle: CandleRequest;
  @Input() nofelId: any;
  public secret: string =_CaptchaKey;
  public addCandleSucsses = false;
  public addCandleErr = false;
  isSubmit =false;
  isLoading = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  addCandle () {
    console.log('send');
       this.isSubmit = true;
 
     if (this.fgInputs.valid) {
        this.recaptcha1.reset();
         this.isLoading = true;
         this.recaptcha1.execute();        
       }
      } 
      
      public submitWithCaptcha(captchaResponse: string) {
         this.candle = new CandleRequest();
         this.candle.Candle = new Candle();
         this.candle.Candle.NofelId = this.nofelId;
         this.candle.GoogleResponseToken = captchaResponse;
         this.candle.Candle.FirstName = this.fgInputs.get('FirstName').value; 
         this.candle.Candle.LastName = this.fgInputs.get('LastName').value; 
         this.candle.Candle.Txt = this.fgInputs.get('Text').value; 
         if(captchaResponse && this.candle.Candle.FirstName != '' ){
         this.http.post(_apiUrl + 'AddCandle' ,this.candle )
         .subscribe(r => {this.addCandleSucsses = true ; this.isSubmit= false; this.isLoading = false; this.fgInputs.reset();}
          ,err => {this.addCandleErr = true; this.isLoading = false;}
          );}
        }
      }
