<section id="eventSignOn" class="pt-3" [class]=" isLoading ? 'isLoading' : '' ">
    <h2>טופס רישום לאירוע</h2>
    <div class="container">
        <div class="row">


            <div class="col-md-12">

                <h3 *ngIf="" class="text-center text-primary  mb-3" title="{{mediot[0]?.title}}">
                    {{mediot[0]?.title}}
                </h3>

                <form id="fgInputs" [formGroup]="fgInputs">
                    <div class="row">

                        <div class="mt-3 col-md-6 text-center pr-0">
                            <div class="form-group">
                                <label for="Erua" class="float-right required"> בחר אירוע לרישום</label>
                                <ng-select [attr.disabled]="true || null" *ngIf="events" class="lg-mb-20 xs-mb-15"
                                    (change)="onChangeErua($event)" [items]="events" bindLabel="event_name"
                                    bindValue="id" formControlName="Erua" title="בחר אירוע "
                                    notFoundText="לא נמצאו תוצאות" [clearable]="true" placeholder="בחר אירוע">
                                </ng-select>
                                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Erua').hasError('required')">
                                    שדה חובה </span>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentEvent &&  currentEvent.content" class="row ">
                        <div [innerHTML]="currentEvent.content"></div>
                    </div>
                    <div class="row">
                        <h5 class="col-md-12 font-weight-bold pr-0 mt-3">פרטי הרושם</h5>
                        <div class="row col-md-12 pr-0">

                            <div class="col-md-3.5 pl-3 pr-3">
                                <div class="form-group">
                                    <label for="firstName" class="float-right required"> שם פרטי </label>

                                    <input type="text" class="form-control" formControlName="FirstName" maxlength="20">
                                    <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('FirstName').hasError('required')">
                                        שדה חובה </span>
                                </div>
                            </div>
                            <div class="col-md-3.5 pl-3 pr-3">
                                <div class="form-group">
                                    <label for="firstName" class="float-right required">שם משפחה</label>
                                    <input type="text" class="form-control" formControlName="LastName" maxlength="30">
                                    <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('LastName').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                            <div class="col-md-3.5 pl-3 pr-3">
                                <div class="form-group">
                                    <!-- <label class="text-danger"> &nbsp; *</label> -->
                                    <label for="firstName" class="float-right">תעודת זהות</label>
                                    <input type="text" class="form-control" formControlName="TZ" maxlength="9">
                                    <span class="errValid" *ngIf="fgInputs.get('TZ') && fgInputs.get('TZ')?.hasError('pattern')" translate> לא
                                        תקין </span>
                                    <!-- <span class="errValid" *ngIf="isSubmit && fgInputs.get('TZ')?.hasError('required')"
                                        translate>
                                        שדה חובה </span> -->
                                    <span class="errValid" *ngIf="fgInputs.get('TZ') && fgInputs.get('TZ')?.hasError('validateTz')" translate>
                                        לא
                                        תקין
                                    </span>


                                </div>
                            </div>
                            <div class="col-md-1.5 pl-3 pr-3">
                                <div class="form-group">
                                    <label for="firstName" class="float-right">גיל</label>
                                    <input min="0" max="120" type="number" class="form-control" formControlName="Age">
                                    <span class="errValid" *ngIf=" fgInputs.get('Age').hasError('max')"> לא תקין
                                    </span>
                                    <span class="errValid" *ngIf=" fgInputs.get('Age').hasError('min')"> לא תקין
                                    </span>

                                    <span class="errValid" *ngIf="isSubmit && fgInputs.get('Age').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 pr-0">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="firstName" class="float-right">כתובת</label>
                                    <input type="text" class="form-control" formControlName="Address" maxlength="50">
                                    <span class="errValid" *ngIf="fgInputs.get('Address').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('Address').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="firstName" class="float-right"> דואר אלקטרוני</label>
                                    <input type="text" class="form-control" formControlName="Email" maxlength="50">
                                    <span class="errValid" *ngIf="fgInputs.get('Email').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('Email').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 pr-0">
                            <div class="col-md-3">
                                <div class="form-group">


                                    <label for="firstName" class="float-right required"> מספר טלפון</label>
                                    <input type="text" class="form-control" formControlName="Phone" maxlength="10">
                                    <span class="errValid" *ngIf="fgInputs.get('Phone').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('Phone').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="firstName" class="float-right">מספר טלפון נוסף</label>
                                    <input type="text" class="form-control" formControlName="Phone2" maxlength="10">
                                    <span class="errValid" *ngIf="fgInputs.get('Phone2').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('Phone2').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>

                            <div class="col-md-3" [hidden]="!nofesh">
                                <div class="form-group">
                                    <label for="firstName" class="float-right">מידת חולצה</label>
                                    <!-- <input type="text" class="form-control" formControlName="SizeOfShirt_M" maxlength="20"> -->
                                    <ng-select class="lg-mb-20 xs-mb-15" [items]="sizes" bindLabel="val" bindValue="key"
                                        formControlName="SizeOfShirt" title="בחר מידה " notFoundText="לא נמצאו תוצאות" [clearable]="true"
                                        placeholder="בחר מידה">
                                    </ng-select>
                                    <span class="errValid" *ngIf="fgInputs.get('SizeOfShirt').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('SizeOfShirt').hasError('required')">
                                        שדה חובה </span>
                
                                </div>
                            </div>
                            <div class="col-md-3" [hidden]="!nofesh">
                                <div class="form-group">
                                    <label for="firstName" class="float-right col-md-12">האם מעשן</label>
                                   
                                    <label class="col-md-3">
                                        <input type="checkbox" class="form-control ml-4" [checked]="fgInputs.get('Eshun')"
                                            formControlName="Eshun">
                                    </label>

                                    <span class="errValid" *ngIf="fgInputs.get('Eshun').hasError('pattern')"> לא
                                        תקין
                                    </span>
                                    <span class="errValid"
                                        *ngIf="isSubmit && fgInputs.get('Eshun').hasError('required')">
                                        שדה חובה </span>

                                </div>
                            </div>
                            
                        </div>

                    </div>

                    <div class="row">
                        <h5 class="col-md-12 font-weight-bold mt-3 pr-0">פרטי החלל</h5>
                        <div class="col-md-3 pr-0">
                            <div class="form-group">
                                <label for="firstName" class="float-right"> קצין רווחה המטפל</label>
                                <input type="text" class="form-control" formControlName="Attendant" maxlength="40">
                                <span class="errValid" *ngIf="fgInputs.get('Attendant').hasError('pattern')"> לא
                                    תקין
                                </span>
                                <span class="errValid"
                                    *ngIf="isSubmit && fgInputs.get('Attendant').hasError('required')">
                                    שדה חובה </span>

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="firstName" class="float-right">שם החלל</label>
                                <ng-select class="lg-mb-20 xs-mb-15" [items]="noflim" bindLabel="val" bindValue="key"
                                    formControlName="Nofel" title="בחר חלל " notFoundText="לא נמצאו תוצאות"
                                    [clearable]="true" placeholder="בחר חלל">
                                </ng-select>
                                <!-- <span class="errValid" *ngIf="isSubmit && fgInputs.get('Nofel').hasError('required')">
                                    שדה חובה </span> -->

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="firstName" class="float-right">סוג קירבה</label>
                                <ng-select class="lg-mb-20 xs-mb-15 " [items]="kirva" bindLabel="val" bindValue="key"
                                    formControlName="Kirva" title="בחר  קירבה" notFoundText="לא נמצאו תוצאות"
                                    [clearable]="true" placeholder="בחר קירבה">
                                </ng-select> 
                                <!-- <span class="errValid"
                                    *ngIf="isSubmit && fgInputs.get('Kirva').hasError('required')"> שדה חובה </span> -->

                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="firstName" class="float-right">יחידה</label>
                                <input type="text" class="form-control" formControlName="Unit" maxlength="20">
                                <span class="errValid" *ngIf="fgInputs.get('Unit').hasError('pattern')"> לא תקין
                                </span>
                                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Unit').hasError('required')">
                                    שדה חובה </span>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="addMishtatef_" class="row">
                        <h5 class="col-md-12 font-weight-bold mt-3 pr-0"> משתתפים נוספים</h5>

                        <button *ngIf="!mishtatefInEdit" (click)="addMishtatef()"
                            class="col-md-2 mr-25 btn btn-outline-dark mt-2 mb-3" type="button">+ הוסף
                            משתתף</button>
                        <div #containMishtatfim class="containMishtatfim containMishtatfim"></div>

                        <div *ngIf="mishtatfim.length" class=" col-12">
                            <h3><span class=" glyphicon glyphicon-pencil"></span></h3>
                            <div *ngFor="let l of this.mishtatfim" class="mishtatfim">
                                <div class="row">
                                    <div class="col-sm-2 col-md-2 col-xs-2  ">
                                        {{l.instance.fgInputsMishtatfim.get('FirstName_M').value +' '+
                                        l.instance.fgInputsMishtatfim.get('LastName_M').value}}</div>
                                    <div *ngIf="!mishtatefInEdit"
                                        class="col-sm-1 col-md-1 col-xs-1 text-right text-primary pt-1">
                                        <i *ngIf="!mishtatefInEdit" class="fa fa-pencil pr-4" aria-hidden="true"
                                            (click)="doOnSelect(l)"></i>
                                        <i *ngIf="!mishtatefInEdit" class="fa fa-trash" aria-hidden="true"
                                            (click)="delete(l)"></i>

                                        <!-- <button type="button"
                                            class="" (click)="doOnSelect(l)">
                                            <small>עדכן</small>
                                        </button> -->
                                    </div>
                                    <!-- <div *ngIf="!mishtatefInEdit"
                                        class="col-sm-1 col-md-1 col-xs-1 text-left text-danger pt-1">
                                        <i *ngIf="!mishtatefInEdit" class="fa fa-trash" aria-hidden="true"
                                            (click)="delete(l)"></i>

                                         <button type="button" class="" (click)="delete(l)"><small>הסר</small></button> 
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <h5 class="col-md-12 font-weight-bold mt-3 pr-0 required"> הסעים</h5>

                        <div class="col-md-4 pr-0">
                            <label class="col-md-6 pr-0">
                                <input type="radio" (click)="check(false)" [value]="false" formControlName="SugHagaa"
                                    RepeatDirection="Horizontal" RepeatLayout="Flow" [attr.disabled]="">הגעה עצמאית
                            </label>
                            <label class="col-md-6 pr-0">
                                <input type="radio" (click)="check(true)" [value]="true" RepeatDirection="Horizontal"
                                    RepeatLayout="Flow" formControlName="SugHagaa">הגעה
                                בהסעה
                            </label>
                            <span class="errValid" *ngIf="isSubmit && fgInputs.get('SugHagaa').hasError('required')">
                                שדה חובה </span>

                        </div>




                        <div [hidden]="!hasaa" class="erua col-md-3">
                            <div class="form-group">
                                <label for="firstName" class="float-right required"> נא לבחור את נקודת
                                    האיסוף</label>
                                <ng-select class="lg-mb-20 xs-mb-15" formControlName="Eir" title="בחר נקודה "
                                    notFoundText="לא נמצאו תוצאות" placeholder="בחר נקודה">
                                    <ng-option [disabled]="o.obCol == 1" *ngFor="let o of arim" value="{{o.key}}">
                                        {{o.val}}</ng-option>
                                </ng-select>
                                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Eir').hasError('required')">
                                    שדה חובה </span>

                            </div>
                        </div>



                    </div>
                    <div class="row">
                        <h5 class="col-md-2 font-weight-bold mt-3 pr-0"> בקשות נוספות</h5>
                        <div class="col-md-10 mt-3">
                            <div class="form-group">
                                <label for="firstName" class="float-right"></label>
                                <input type="text" class="form-control" formControlName="Comments" maxlength="500">
                                <span class="errValid" *ngIf="fgInputs.get('Comments').hasError('pattern')"> לא תקין
                                </span>
                                <span class="errValid"
                                    *ngIf="isSubmit && fgInputs.get('Comments').hasError('required')">
                                    שדה חובה </span>

                            </div>
                        </div>
                    </div>
                    <div class="text-danger text-center mt-2" *ngIf="this.mishtatefInEdit && this.textAttention ">{{this.textAttention}}</div>
                    <div class="row">
                        <div class="form-group col-12">
                            <button id="send" type="button" (click)="send()"
                                class="btn btn-warning active font-weight-bold col-md-2">שלח/י</button>
                        </div>
                    </div>



                </form>

                <!-- <div class="alert alert-success" *ngIf="signOnSucsses">
                    <p>פנייתך התקבלה בהצלחה</p>
                </div>
                <div class="alert alert-danger" *ngIf="signOnErr">
                    <p>אירעה שגיאה בלתי צפויה</p>
                </div> -->
            </div>


        </div>
    </div>

    <re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}">
    </re-captcha>
</section>
<div class="loadingDiv" *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
</div>