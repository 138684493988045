import { Component, EventEmitter, Input, OnInit , Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RecaptchaComponent} from 'ng-recaptcha';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { SearchParams } from '../entities/SearchParams';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchResults } from '../entities/SearchResults';
import { MediaSectionsService } from '../services/mediaSectionsService';
import { map } from 'rxjs/operators';

declare var _apiUrl: any ;
declare var _CaptchaKey: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.styl']
})
export class SearchComponent implements OnInit {

  @ViewChild(RecaptchaComponent) recaptcha1: RecaptchaComponent;
  @Input() isSearchResult: any = false;
  @Output() searchResultEvent = new EventEmitter<boolean>();
  public searchResult$: Observable<any>;
  public isSpecificSearch = false;
  searchParams: SearchParams;
  public secret: string =_CaptchaKey;
  public isLoading = false;
  isNoSearchResult= false;
 
  public  mechozot;
  public units;
  public cemeteries;
  public noflim;
  generalData:any;
  isNoOne = false;
  isNoSelect = false;
  public mediot:Observable<any>;
  minDate = new Date(1900 , 1 ,1);
  maxDate = new Date();
  isIron = "false";

  fgInputs = new FormGroup({
    Nofel: new FormControl( ),
    LastName: new FormControl('', [Validators.maxLength(20),  Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    FirstName: new FormControl('', [Validators.maxLength(20),  Validators.pattern('^[^!@#$%^&*0123456789)(_+|:?<>~]+$')]),
    Ishi: new FormControl('', [ Validators.maxLength(10), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    FromDate: new FormControl(''),//, [ Validators.maxLength(10), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    ToDate: new FormControl(''),//, [ Validators.maxLength(10), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    ExactDate: new FormControl(''),//, [ Validators.maxLength(10), Validators.pattern('^(0|[1-9][0-9]*)$')]),
    selectMachoz: new FormControl(),
    selectUnit: new FormControl(),
    selectCemetery: new FormControl(),
    isSubmit: new FormControl('0'),
    reset: new FormControl('1')
  }
);

  constructor(private http: HttpClient , private router: Router , private route: ActivatedRoute, private searchReasult: SearchResults ,  public mediaService: MediaSectionsService)  {
   

   }

  ngOnInit(): void {
    this.mediaService.dataMedia
    .pipe(map(items => items.filter(item => item.sectionId == 8)))
    .subscribe(d => {
      this.mediot = d;    
    });

    this.http.get(_apiUrl + 'GeneralData')
  .subscribe(n => {this.generalData = n ; 
    this.mechozot=this.generalData.mechozot;
    this.cemeteries = this.generalData.cemeteries;
    this.noflim = this.generalData.noflim;
    this.units = this.generalData.units;});
   
    this.isIron = this.route.snapshot.paramMap.get('isIron');
  }

  machozChanged(event) {

  }
  clearSelect(){}

searchNofel(){
  if(this.fgInputs.get('Nofel').value == null || this.fgInputs.get('Nofel').value == ""){
    this.isNoSelect = true;
  }
  else{
  this.isNoSelect = false;
  var nav = '/nofel/' +this.fgInputs.get('Nofel').value;
  this.router.navigate([nav]);
  }
}

  search() {
    console.log('send');
      // this.isSubmit = 1;
       //this.fgInputs.get('isSubmit').setValue('1');
 
           if (this.fgInputs.valid) {  
            if(this.fgInputs.get('ToDate').value == '' &&
            this.fgInputs.get('FromDate').value == '' &&
            this.fgInputs.get('selectCemetery').value == null &&
            this.fgInputs.get('selectMachoz').value == null &&
            this.fgInputs.get('selectUnit').value == null &&
            this.fgInputs.get('Ishi').value == '' &&
            this.fgInputs.get('FirstName').value == '' &&
            this.fgInputs.get('LastName').value == ''             
            )
            {
              this.isNoOne = true;
            }
            else{
              this.isNoOne = false;
             this.recaptcha1.reset();
             this.isLoading = true;
             this.recaptcha1.execute();
            }
       }
      } 
      
      public submitWithCaptcha(captchaResponse: string) {
        this.isNoSearchResult = false;
        if(this.fgInputs.get('ExactDate').value != '')
        {
        var exactDate = 
        this.fgInputs.get('ExactDate').value.format('YYYY-MM-DD');
        }
        if(this.fgInputs.get('FromDate').value != '')
        {
        var fromDate = 
        this.fgInputs.get('FromDate').value.format('YYYY-MM-DD');
        }
        if(this.fgInputs.get('ToDate').value != '')
        {
        var toDate = 
        this.fgInputs.get('ToDate').value.format('YYYY-MM-DD');
        }
         this.searchParams = new SearchParams();
         this.searchParams.GoogleResponseToken = captchaResponse;
          this.searchParams.FirstName = this.fgInputs.get('FirstName').value;            
          this.searchParams.LastName = this.fgInputs.get('LastName').value;            
          this.searchParams.Ishi = this.fgInputs.get('Ishi').value;            
          this.searchParams.DateNefilaFrom = fromDate;            
          this.searchParams.DateNefilaTill = toDate;            
          this.searchParams.DateNefila = exactDate;            
         
          this.searchParams.MachozId = this.fgInputs.get('selectMachoz').value;
          this.searchParams.UnitId = this.fgInputs.get('selectUnit').value;
          this.searchParams.CemeteryId = this.fgInputs.get('selectCemetery').value;
         if(this.isIron=="true"){
          this.searchParams.SibatMavetID = 10641          
         }
         
         if(captchaResponse){
          this.searchResult$ =  this.http.post(_apiUrl + 'Noflim' ,this.searchParams );
          this.searchResult$.subscribe(s => {this.isLoading= false; 
            this.searchReasult.searchResult$ = this.searchResult$;
            console.log(this.searchReasult.searchResult$);
            if(s?.length ==0){
              this.isNoSearchResult = true;
              this.searchResultEvent.emit(true);
            }
            else{
              this.isNoSearchResult = false;     
              this.searchResultEvent.emit(false);    
          if(!this.isSearchResult)
          this.router.navigate(['/searchResults'])}
        } );}
      }
         
                  }
      
              
