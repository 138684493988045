import { FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class InputsValidators {

   public validateTz(c: FormControl) {
    try {
      let IDnum = c.value.toString();
      let isValid = true;

      if (IDnum.length > 0) {

        // Validate correct input
        if ((IDnum.length > 9) || (IDnum.length < 5)) {
          isValid = false;
        } else if (isNaN(IDnum)) {
          isValid = false;
        } else {
          // The number is too short - add leading 0000
          if (IDnum.length < 9) {
            while (IDnum.length < 9) {
              IDnum = '0' + IDnum;
            }
          }
          // CHECK THE ID NUMBER
          let mone = 0, incNum;
          for (let i = 0; i < 9; i++) {
            incNum = Number(IDnum.charAt(i));
            incNum *= (i % 2) + 1;
            if (incNum > 9) {
              incNum -= 9;
            }
            mone += incNum;
          }
          if (mone % 10 === 0) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
        console.log(IDnum);
        console.log(IDnum === '000000000');
        if (IDnum === '000000000') {
          isValid = false;
        }
      }
      if (isValid) {
        return null;
      } else {
        return {
          validateTz: {
            valid: false
          }
        };
      }
    } catch (e) {
      return {
        validateTz: {
          valid: false
        }
      };
    }
  }
}