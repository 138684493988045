<section id="contactUs" class="pt-3" [class]=" isLoading ? 'isLoading' : '' ">
  <div class="container">
    <div class="row">

      <div class="col-md-7">

        <h3 *ngIf="mediot" class="text-center text-primary  mb-3" title="{{mediot[0]?.title}}">{{mediot[0]?.title}}</h3>
        <div [innerHTML]="media.text" *ngFor="let media of mediot | filterMediaPipe:4;">
        </div>

        <form [formGroup]="fgInputs">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right">שם פרטי</label>
                <input type="text" class="form-control" formControlName="FirstName" maxlength="20">
                <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('FirstName').hasError('required')"> שדה חובה
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right">שם משפחה</label>
                <input type="text" class="form-control" formControlName="LastName" maxlength="20">
                <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('LastName').hasError('required')"> שדה חובה
                </span>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right">יחידה</label>
                <input type="text" class="form-control" formControlName="Unit" maxlength="20">
                <span class="errValid" *ngIf="fgInputs.get('Unit').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Unit').hasError('required')"> שדה חובה </span>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right">שם החלל</label>
                <ng-select class="lg-mb-20 xs-mb-15" [items]="noflim" bindLabel="val" bindValue="key"
                  formControlName="Nofel" title="בחר חלל " notFoundText="לא נמצאו תוצאות" [clearable]="true"
                  placeholder="בחר חלל">
                </ng-select>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Nofel').hasError('required')"> שדה חובה </span>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right">קירבה לחלל</label>
                <ng-select class="lg-mb-20 xs-mb-15 " [items]="kirva" bindLabel="val" bindValue="key"
                  formControlName="Kirva" title="בחר  קירבה" notFoundText="לא נמצאו תוצאות" [clearable]="true"
                  placeholder="בחר קירבה">
                </ng-select> <span class="errValid" *ngIf="isSubmit && fgInputs.get('Kirva').hasError('required')"> שדה
                  חובה </span>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="firstName" class="float-right"> טלפון</label>
                <input type="text" class="form-control" formControlName="Phone" maxlength="10">
                <span class="errValid" *ngIf="fgInputs.get('Phone').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Phone').hasError('required')"> שדה חובה </span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName" class="float-right"> מייל</label>
                <input type="email" class="form-control" formControlName="Mail" maxlength="50">
                <span class="errValid" *ngIf="fgInputs.get('Mail').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Mail').hasError('required')"> שדה חובה </span>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName" class="float-right"> סוגי התקשרוית</label>
                <ng-select class="lg-mb-20 xs-mb-15 " [items]="categories" bindLabel="val" bindValue="key"
                  formControlName="ContactType" title="בחר קטגוריית פניה" notFoundText="לא נמצאו תוצאות"
                  [clearable]="true" placeholder="בחר קטגוריה">
                </ng-select>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('ContactType').hasError('required')"> שדה חובה
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="firstName" class="float-right">השאר לנו הודעה</label>
                <textarea class="form-control" id="" rows="3" formControlName="Subject" MaxLength="100"></textarea>
                <span class="errValid" *ngIf="fgInputs.get('Subject').hasError('pattern')"> לא תקין </span>
                <span class="errValid" *ngIf="isSubmit && fgInputs.get('Subject').hasError('required')"> שדה חובה
                </span>

              </div>
            </div>
            <div class="col-md-12 ">
              <div class="form-group">
                <button type="button" (click)="send()" class="btn btn-outline-warning float-left">שלח/י</button>
              </div>
            </div>
          </div>
          <!--        <div class="row">
                              <div class="col-md-4">
                                  <div class="inner-col">
                                    <span class="upperText" >שם פרטי</span>
                                    <input type="text"  class="form-control" class="form-control" formControlName="FirstName"  maxlength="20" >
                                    <span class="errValid" *ngIf="fgInputs.get('FirstName').hasError('pattern')"> לא תקין </span>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="inner-col">        
                                    <span class="upperText" >
                                      <span class="star">*</span>
                                      <span >טלפון</span>
                                      </span>
                                    <input  type="tel"  class="form-control" formControlName="Phone"  MaxLength="10"  >
                                    <span class="errValid" *ngIf="fgInputs.get('Phone').hasError('required')" >שדה חובה</span>
                                    <span class="errValid" *ngIf="fgInputs.get('Phone').hasError('pattern')" >  לא תקין  </span>
                                  </div>
                                </div>  
                                <div class="col-md-4">
                                  <div class="inner-col">
                                    <span class="upperText" >שם משפחה</span>
                                    <input type="text" class="form-control" formControlName="LastName"  maxlength="20">
                                    <span class="errValid" *ngIf="fgInputs.get('LastName').hasError('pattern')"> לא תקין  </span>
                                  </div>
                                </div>       
                                <div class="col-md-4">
                                  <div class="inner-col">
                                    <span class="upperText" >דואר אלקטרוני</span>
                                    <input type="email" #Mail class="form-control" formControlName="Mail"  maxlength="50">
                                    <span class="errValid" *ngIf="fgInputs.get('Mail').hasError('pattern')" >לא תקין  </span>
                                  </div>
                                </div>
                                <div class="col-md-12 col-sm-12" >
                                  <div class="inner-col">
                                    <span class="upperText" >נושא הפניה</span>
                                    <input type="text" class="form-control" formControlName="Subject"  MaxLength="100"  title="נושא הפניה">
                                    <span class="errValid" *ngIf="fgInputs.get('Subject').hasError('pattern')"> לא תקין </span>
                                  </div>
                                </div>   
                        </div>-->
        </form>

        <div class="alert alert-success" *ngIf="contactSucsses">
          <p>פנייתך התקבלה בהצלחה</p>
        </div>
        <div class="alert alert-danger" *ngIf="contactErr">
          <p>אירעה שגיאה בלתי צפויה</p>
        </div>
      </div>

      <div class="col-md-5" *ngFor="let media1 of mediot | filterMediaPipe:1;">
        <img src={{mediaService.mediaPath+media1.url}} alt="{{media1.title}}" title="{{media1.title}}"
          class="img-fluid h-100">
      </div>
    </div>
  </div>

  <re-captcha #captchaRef="reCaptcha" size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}">
  </re-captcha>

</section>
<div class="loadingDiv" *ngIf="isLoading">
  <img src="assets\img\laoding.gif">
</div>