<section id="mishpacha-title" class="yizkor-title" [class]=" isLoading ? 'isLoading' : '' ">
    <header>
      <div>
        <ng-container *ngFor="let mediavieo of mediot | filterMediaPipe:2; let i = index ">
  
          <video class="d-block w-100 " playsinline="playsinline" autoplay="autoplay" [muted]="false" loop="loop" controls="controls">
            <source src={{mediaService.mediaPath+mediavieo.url}} type="video/mp4">
          </video>
          <div *ngIf="mediavieo.text" class="carousel-caption d-none d-md-block" [innerHTML]="mediavieo.text"></div>
        </ng-container>
        <ng-container *ngFor="let mediaYout of mediot | filterMediaPipe:3; let i = index ">
  
          <iframe class="h-700 bg-dark text-center w-100 vh-100 d-block" [src]="urlIframe + mediaYout.url | safe"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <div *ngIf="mediaYout.text" class="carousel-caption d-none d-md-block" [innerHTML]="mediaYout.text"></div>
  
        </ng-container>
      </div>
      <div *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 0" id="carouselExampleControls" class="carousel slide position-relative " data-ride="carousel">
        <div class="carousel-inner">
          <diV *ngFor="let mediaimg of mediot | filterMediaPipe:1; let i = index "
            [ngClass]="{'active': i == 0, 'carousel-item': mediaimg.mediaType!=4, 'vh-100': mediaimg.mediaType==2}"
            class=" ">
            <img src={{mediaService.mediaPath+mediaimg.url}} class="d-block w-100" alt="{{mediaimg.title}}"
              title="{{mediaimg.title}}">
            <div class="carousel-caption d-none d-md-block" [innerHTML]="mediaimg.text" *ngIf="mediaimg.text"></div>
          </diV>
        </div>
        <a *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 1" class="carousel-control-prev mt-5" href="#carouselExampleControls" role="button"
          data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="mediot && (mediot | filterMediaPipe : 1).length > 1" class="carousel-control-next position-absolute float-left mt-70"
          href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div> 
  
    </header>
  </section>
  <div class="loadingBgDiv"  *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
  </div>
