import { HttpClient } from '@angular/common/http';
import {  Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';

declare var _apiUrl: any ;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.styl']
})
export class NavBarComponent implements OnInit  {

  public menu : any;
  public mediot: Observable<any>;

  constructor(private http: HttpClient ,private router: Router ,public mediaService: MediaSectionsService ) { }

  ngOnInit(): void {
    this.http.get(_apiUrl + 'Menu' )
    .subscribe(n => {this.menu = n ; });      

    this.mediaService.dataMedia
    .pipe(map(items => items.filter(item => item.sectionId == 20)))
    .subscribe(d => {
      this.mediot = d;    
    });
   
  }

  /*ngAfterViewInit():void{
    var og = window.location.href.split('#');
    console.log('og-oninit');
    console.log(og);
    if(og!=null && og[1] != null)
    {
      setTimeout(()=>{
        this.scrollTo(window.location.href);
      },5000);     
    }
  
    if(og.length>1){
      
    }
  

  }*/

  GetLiClass(menuItem: any) : string {
   var cl = 'nav-item';
   if(menuItem.parentId > 0 )
   cl += ' dropdown';
   if(menuItem.link == window.location.href)
   cl += ' active';   
   return cl;
  }
  getLinkHome() :string{
    return window.location.origin;
  }

  scrollTo(url:string){  
    console.log('og');
    console.log(url);
    var og = url.split('#');
    console.log(og);
   var locInFamelies = window.location.href.includes('withthefamilies');
   var ogRefFamelies =  og[0].includes('withthefamilies');
   console.log(locInFamelies);
   console.log(ogRefFamelies);
    if(locInFamelies == ogRefFamelies)
    {
  var control: HTMLElement = document.querySelectorAll('#' + og[1])[0] as HTMLElement;

  console.log(control);
  if(control){
  console.log( this.getTopOffset(control));

    window.scroll({
      top: this.getTopOffset(control),
      left: 0,
      behavior: "smooth"
    });   
   // if(ogRefFamelies)
   // this.router.navigate(['/withthefamilies/']);
    //else 
    //this.router.navigate(['/']);  
  }
  }
  else{
  this.router.navigate([url]);
  }
}

   private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.offsetTop - labelOffset;
  }   
  scroll0(){
    window.scrollTo(0, 0);
  }

}
