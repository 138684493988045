<div *ngIf="!isLoading" class="container contact-page" [class]=" isLoading ? 'isLoading' : '' " >
    <header> 
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel" >     
                <a *ngIf="page?.topGallery?.length > 1" class="btn-floating" href="#carouselExampleSlidesOnly" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                <a *ngIf="page?.topGallery?.length > 1" class="btn-floating" href="#carouselExampleSlidesOnly" data-slide="next"><i class="fa fa-chevron-right"></i></a>
             
            <div class="carousel-inner">
                <div *ngFor="let media1 of page?.topGallery | filterMediaPipe:1; let first = first;"   [class.active]="first" class="carousel-item" >
                    <img class="d-block w-100" src={{mediaPath+media1.url}} alt="{{media1.title}}" title="{{media1.title}}">
                         <div [innerHTML]="media.text" *ngFor="let media of page.topGallery | filterMediaPipe:3;" class="carousel-caption d-none d-md-block">
                        </div>
                  </div>
            </div>
        </div>
  </header>
  <section id="" class="py-2 mt-3">
    <div class="container ">
        <div class="row">           
            <div class="col-md-12 align-self-center">
                <h1>{{page?.page.title}}</h1>
                <div [innerHTML]="page?.page.mainText" >
                </div>
            </div>
                    
        </div>
    </div>
</section>

<section id="carousel">
			
    <div class="text-center my-3 bg-white">
        <h5>{{page?.page.mediaTitle}}</h5>
        <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel" data-interval="false">

                <a  *ngIf="bootomCarusle?.length > 1" class="btn-floating" href="#multi-item-example" data-slide="prev"><i class="fa fa-chevron-left"></i></a>
                <a  *ngIf="bootomCarusle?.length > 1" class="btn-floating" href="#multi-item-example" data-slide="next"><i class="fa fa-chevron-right"></i></a>
            

              <div class="carousel-inner" role="listbox">
                <div *ngFor="let row of bootomCarusle; let first = first;"   [class.active]="first" class="carousel-item " >
                    <div class="row col-12">
                        <div *ngFor="let media of row; let i = index"  [class] = getClass(i)>
                            <div class="card mb-2">
                                <img class="card-img-top" src={{mediaPath+media.url}} alt="{{media.title}}" title="{{media.title}}">
                                 <div class="card-body">
                                  <h4 class="card-title">{{media.title}}</h4>
                                  <p [innerHTML]="media.text" class="card-text"></p>  
                                 <!-- <a class="btn btn-primary">Button</a>-->
                                </div>
                              </div>
                       </div>
                   </div>
                </div>
              </div>
        </div>
    </div>
</section>    

<app-share [title] = "sahreTitle" [img] = "shareImg"></app-share>

</div>
<div class="loadingBgDiv"  *ngIf="isLoading">
    <img src="assets\img\laoding.gif">
</div>