export class EventSignOn{
    GoogleResponseToken: string;
    FirstName: string;
    LastName: string;
    Tz: string;
    Address:string;
    Age: number;
    Email:string;
    SizeOfShirt:number;
    Phone:string;
    Phone2:string;
    Eshun:boolean;
    Attendant:string;
    Yechida:string;
    Hasaa:boolean;
    Comments:string;

    
    KirvaId: number;
    NofelId: number;
    EirId:number;
    EventId: number;
  }