import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterMediaPipe',
    pure: false
})
export class filterMediaPipe implements PipeTransform {
    transform(items: any[], filter: number): any {
        if (!items || !filter) {
            return items;
        }
        
        var a=items.filter(item => item.mediaType == filter);
      // debugger;
        return a;
    }
}