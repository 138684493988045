<section id="candle" class="bg-white candle-section">
  <div class="container">
      <div class="col text-center py-5">
        <h2 *ngIf="mediot" class="display-4" title="{{mediot[0]?.title}}">{{mediot[0]?.title}}</h2>
        <span  *ngIf="mediot" [innerHTML]="mediot[0]?.text"></span>
        <form [formGroup]="fgInputs" class="justify-content-center">
          <div class="row justify-content-center align-items-center">
            <div class="col-md-12 row">
              <div class="col-md-6 candle-search">
                <ng-select class="lg-mb-20 xs-mb-15" [items]="noflim" bindLabel="val" bindValue="key"
                formControlName="Nofel" title="בחר חלל " notFoundText="לא נמצאו תוצאות" [clearable]="true"
                placeholder="בחר חלל">
              </ng-select> 
               <span class="errValid" *ngIf="isSubmit && fgInputs.get('Nofel').hasError('required')"> שדה חובה  </span>
                 
                  <a (click) ="search()" class="link">חפש</a>

              </div>
         <div class="col-md-6 ">
          <button *ngIf="!isSubmitSucsses" (click) = "addGeneralCandle()" class="btn btn-primary btn-lg  mt-2">
            <img src="assets\img\onCandle.png">
            הדליקו נר לזכרם</button>
            <button *ngIf="isSubmitSucsses"  class="btn btn-primary btn-lg  mt-2 noClick">
              <img src="assets\img\offCandle.png">
             הנר הודלק בהצלחה</button>
          <label [class]=" isSucsses ? 'isCandleSucsses' : '' " class="countGeneralCandles">עד היום הודלקו {{countGeneralCandles}}   נרות כלליים</label>
          <label *ngIf="isSubmitErr"  class="countGeneralCandlesErr">אירעה שגיאה בלתי צפויה  </label>
         
          <div class="loadingCandle"  *ngIf="isLoading">
            <img src="assets\img\laodingCandle.gif">
          </div>
        </div>
          </div>
          </div>
         </form>
      </div>
  </div>
</section>
          <!--   <div class="col-6">
          <label for="">הזן שם חלל</label>
          <input type="text" formControlName ="FreeText" class="form-control mb-2 mr-sm-2" id="">
          <button type="button" (click) ="search()" class="btn btn-dark mb-2">חפש</button>
        </div>
        <div class="col-6">
          <label for="">נר כללי</label>
          <button type="button" (click) = "addGeneralCandle()" class="btn btn-dark mb-2">הדלק נר לזכרם</button>
          <label>עד היום הודלקו {{countGeneralCandles}} נרות</label>
        </div>
        </form>
      </div>
    </div>-->
   <!-- <re-captcha #captchaRef="reCaptcha"  size="invisible" (resolved)="submitWithCaptcha($event)" siteKey="{{secret}}"></re-captcha>-->