import { Component, ElementRef, OnInit, Output,Input, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';
import { SearchParams } from '../entities/SearchParams';
import { HttpClient } from '@angular/common/http';
import { SearchResults } from '../entities/SearchResults';
import { Router } from '@angular/router';

declare var _iframeUrl: any;
declare var _apiUrl: any ;

@Component({
  selector: 'app-yizkor-title',
  templateUrl: './yizkor-title.component.html',
  styleUrls: ['./yizkor-title.component.styl']
})
export class YizkorTitleComponent implements OnInit {

  constructor(public mediaService: MediaSectionsService, private http: HttpClient, private searchReasult: SearchResults, private router: Router) { }
  public mediot: Observable<any>;

  public urlIframe: string;
  public length_:number;
  isLoading = true;
  searchParams: SearchParams;
  public searchResult$: Observable<any>;
  @Input() isSearchResult: any = false;
  @Output() searchResultEvent = new EventEmitter<boolean>();
  isNoSearchResult= false;

  @ViewChild('videoplayer') videoplayer: ElementRef;

  ngOnInit(): void {


    this.urlIframe = _iframeUrl;
    this.mediaService.dataMedia
      .pipe(map(items => items.filter(item => item.sectionId == 1)))
      .subscribe(d => {
        this.mediot = d;
        this.isLoading=false;
        this.length_ = d.length;
            });

  }
  // toggleVideo(event: any) {
  //   this.videoplayer.nativeElement.play();
  // }

  search() {
    console.log('send');
      
         this.searchParams = new SearchParams();
          this.searchParams.SibatMavetID = 10641             
         
         
        //  if(captchaResponse){
          this.searchResult$ =  this.http.post(_apiUrl + 'Noflim' ,this.searchParams );
          this.searchResult$.subscribe(s => {this.isLoading= false; 
            this.searchReasult.searchResult$ = this.searchResult$;
            console.log(this.searchReasult.searchResult$);
            if(s?.length ==0){
              this.isNoSearchResult = true;
              this.searchResultEvent.emit(true);
            }
            else{
              this.isNoSearchResult = false;     
              this.searchResultEvent.emit(false);    
          if(!this.isSearchResult)
          this.router.navigate(['/searchResults', {isIron: true}])}
        } )
      }
         
      

}
