<app-yizkor-title></app-yizkor-title>
<div class="container">
    <div class="row">
        <div class="col-md-6">
<app-noflim-to-day></app-noflim-to-day>
</div>
<div class="col-md-6">
<app-search></app-search>
</div>
  </div>
</div>
<div class="container ">
 <div class="row">
 <div class="col-md-6 ">
  <app-tekes-azkara></app-tekes-azkara>
<app-candle-section></app-candle-section>
</div>
<div class="col-md-6 ">
<app-yizcor-text></app-yizcor-text>
  </div>
  </div>
</div>
<app-lezichram></app-lezichram>
<app-mishpacha></app-mishpacha>
<app-contact></app-contact>
<app-share [title] = "sahreTitle" [img] = "shareImg"></app-share>

