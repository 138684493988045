<section class="container">
    <div> 

       <h5 tabindex="0">שתף:</h5>
       <a class="btn circle" (click)="goTwitter()" title="שתף בטוויטר"><i class="fa fa-twitter"></i></a>
       <a (click)="goFacebook()" class="btn circle" title="שתף בפייסבוק"><i class="fa fa-facebook"></i></a>
       <a (click)="goWhatsapp()" class="btn circle whatsapp" title="שתף בוואטסאפ"><i class="fa fa-whatsapp"></i></a>
    
       <a class="btn circle"title="העתקה" (click)="copy()" >
         <i class="fa fa-files-o"></i>
       </a>
       <span class="is-copy" *ngIf = "isCopy">הועתק</span>







 </div>
</section>
