export class MishtatefEvent{
    GoogleResponseToken: string;
    FirstName: string;
    LastName: string;
    KirvaId: number;
    Age: number;
    DateOfBirth:Date;
    SizeOfShirt: number;
    GreenCharacter:boolean;
    RoshemId:number;
  }