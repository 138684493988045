
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'MyFormatTimePipe',
    pure: false
})
export class MyFormatTimePipe implements PipeTransform {
    transform(_date: string): any {
        if (!_date ) {
            return _date;
        }
        var d = new Date(_date);
        var a= (d.getHours() < 10 ? "0"+d.getHours() : d.getHours()) +":"+(d.getMinutes() < 10 ? "0"+ d.getMinutes() : d.getMinutes()) ;
        
        return a;
    }
}