import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaSectionsService } from '../services/mediaSectionsService';
declare var _iframeUrl: any;


@Component({
  selector: 'app-mishpacha',
  templateUrl: './mishpacha.component.html',
  styleUrls: ['./mishpacha.component.styl']
})
export class MishpachaComponent implements OnInit {

  public mediot:Observable<any>;
  public iframeUrl:string;
  constructor(  public mediaService: MediaSectionsService
    ) { }
  ngOnInit(): void {
    this.iframeUrl=_iframeUrl;
    this.mediaService.dataMedia.pipe(map (items=> items.filter(item=>item.sectionId==4)))
    .subscribe(d => {
      //debugger;
        this.mediot= d ;
        
    });
  }

}
